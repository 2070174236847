import { Box, Grid, Typography, useTheme } from "@mui/joy";
import SessionContext from "../../store";
import { useContext } from "react";
import PrimaryButton from "../UI/custom_elements/primaryButton";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import usePost from "../../hooks/usePost";

const ActiveClient = ({close, client, fetchData}) => {

    const { session } = useContext(SessionContext);

    const theme = useTheme();

    const {error, post} = usePost();

    if(error){
        console.log(error);
    }

    const active = async () => {
        const clientPhone = {
            mobile: client.mobile,
            country_code: client.country_code
        }
        console.log(clientPhone)
        try {
            const response = await post("/admin/users/unblock", clientPhone, session.token);
            if(response){
                fetchData();
                close();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box width={500}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "16px",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        ¿Está seguro que quiere activar el usuario?
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                        <Grid md={1}>
                            <SecondaryButton click={()=> close()} text="Cancelar" full={true} />
                        </Grid>
                        <Grid md={1}>
                            <PrimaryButton click={() => active()} text="Aceptar" full={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
} 

export default ActiveClient;