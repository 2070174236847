import { Box, Grid, Typography, useTheme } from "@mui/joy";

import TitleDocument from "../titleDocument";
import PrimaryButton from "../../UI/custom_elements/primaryButton" 
import SecondaryButton from "../../UI/custom_elements/secondaryButton" 

const Step2 = ({step, nextStep ,decline , title, url, file_1, url_2 = undefined, file_2 = undefined, url_3 = undefined, file_3 = undefined, url_4 = undefined, file_4 = undefined}) => {

    const theme = useTheme();

    const handleDownload = (new_url) => {
        window.open(new_url, '_blank');
    };
    
    return (

        <>
            <TitleDocument step={step} title={title} />
            <Box sx={{
                borderRadius: "24px",
                padding: "24px",
                backgroundColor: theme.vars.palette.card
            }}>
                <Box>
                    <Box>
                        <Typography sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {file_1}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <SecondaryButton click={() => handleDownload(url)} text="Descargar" document={true} table={true} full={true} />
                    </Box>
                </Box>
                {
                    url_2 && file_2 && (
                        <Box mt={4}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    {file_2}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <SecondaryButton click={() => handleDownload(url_2)} text="Descargar" document={true} table={true} full={true} />
                            </Box>                        
                        </Box>
                    )
                }
                {
                    url_3 && file_3 && (
                        <Box mt={4}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    {file_3}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <SecondaryButton click={() => handleDownload(url_3)} text="Descargar" document={true} table={true} full={true} />
                            </Box>                        
                        </Box>
                    )
                }
                {
                    url_4 && file_4 && (
                        <Box mt={4}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    {file_4}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <SecondaryButton click={() => handleDownload(url_4)} text="Descargar" document={true} table={true} full={true} />
                            </Box>                        
                        </Box>
                    )
                }
            </Box>
            <Box mt={2}>
                <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <SecondaryButton click={()=> decline()} text="Rechazar" full={true} />
                    </Grid>
                    <Grid md={1}>
                        <PrimaryButton click={() => nextStep()} text={"Siguiente"} full={true} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
} 

export default Step2;