import { Box, Snackbar } from "@mui/joy";
import { useContext, useEffect, useState } from "react";

import Step2 from "./steps/step2";
import Dialog from "../UI/custom_elements/dialog";
import DeclineDocuments from "./declineDocuments"
import ValidateAnswer from "./validateAnswer";
import useGet from "../../hooks/useGet";
import SessionContext from "../../store";
import usePost from "../../hooks/usePost";
import { Loading } from "../UI/custom_elements/loading";
import Step1 from "./steps/step1";
import DocumentsStatusView from "./DocumentsStatusView";

const ValidateDocument = ({close, merchant, fetchData: fetchDataTable}) => {

    const [step, setStep] = useState(1);
    const [decline, setDecline] = useState(false);
    const [declineIndex, setDeclineIndex] = useState(false);
    const [validateIndex, setValidateIndex] = useState(false);
    const [check, setCheck] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState({})
    const [errorMessage, setErrorMessage] = useState(false)

    const {error, isLoading, get} = useGet();
    const { post } = usePost()
    const { session } = useContext(SessionContext);

    const [validations, setValidations] = useState([
        { document: "business_name", approved: true },
        { document: "identity_name_number", approved: true },
        { document: "identity_expiration", approved: true },
        { document: "rif_business_name", approved: true },
        { document: "rif_expiration", approved: true },
        { document: "rif_business_name_number", approved: true },
        { document: "tax_declaration", approved: true },
        { document: "address_rif", approved: true },
        { document: "location_metadata", approved: true },
        { document: "business_ownership", approved: true },
    ]);
    
    if(error){
        console.log(error);
    }
    
    const fetchData = async () => {
        try {
            const data = await get("/admin/merchant/requests/" + merchant, session.token);
            if(data)
                setMerchantInfo(data)
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getStatus = () => {
        if (validations.some((validation) => !validation.approved)) {
          return "rejected";
        } else {
          return "approved";
        }
    };

    const validateRequest = async () => {
        try{
            const body = {
                requestid: merchantInfo.id,     
                status: getStatus(),
                validations: validations
            }
            console.log(body)
            const response = await post("/admin/merchant/requests/validate", body, session.token);
            if(response){
                fetchDataTable();
                close();
            }
            else{
                setErrorMessage(true);
            }
        }
        catch(error){

        }
    }

    const nextStep = () => {
        if(step === 11){
            validateRequest();
        }
        else{
            setStep(prev => prev + 1);
        }
    }

    const validateAnswer = (index) => {
        setCheck(true);
        setValidateIndex(index)
    }

    const declineDocument = (index) =>{
        setDecline(true);
        setDeclineIndex(index)
    }

    const updateValidation = (index, text) => {
        setValidations((prevValidations) => {
          const updatedValidations = [...prevValidations];
          
          if (index >= 0 && index < updatedValidations.length) {
            updatedValidations[index] = {
              ...updatedValidations[index],
              approved: false,
              reason: text,
            };
          }
      
          return updatedValidations;
        });
    };

    const approveDocument = (index) => {
        setValidations((prevValidations) => {
          const updatedValidations = [...prevValidations];
      
          if (index >= 0 && index < updatedValidations.length) {
            updatedValidations[index] = {
              ...updatedValidations[index],
              approved: true,
            };
      
            if ("reason" in updatedValidations[index]) {
              delete updatedValidations[index].reason;
            }
          }
      
          return updatedValidations;
        });
    };

    if(isLoading){
        return(
            <Box display="flex" justifyContent="center" width={500}>
                <Loading />
            </Box>
        )
    }

    return (
        <>
            <Dialog title="" open={decline} close={()=> setDecline(false)}>
                <DeclineDocuments close={()=> setDecline(false)} index={declineIndex} updateValidation={updateValidation} nextStep={nextStep} />
            </Dialog>
            <Dialog title="" open={check} close={()=> setCheck(false)}>
                <ValidateAnswer close={()=> setCheck(false)} nextStep={nextStep} approveDocument={approveDocument} index={validateIndex} />
            </Dialog>
            <Snackbar
                open={errorMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                variant="solid"
                color="danger"
                autoHideDuration={4000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setErrorMessage(false);
                }}
            >
                Ocurrió un problema con la validación, intente más tarde   
            </Snackbar> 
            <Box width={500}>
                {
                    step === 1 && (
                        <Step1 step={step} nextStep={() => validateAnswer(0)} decline={()=> declineDocument(0)} 
                            title={"Verificación del nombre del comercio"} url={merchantInfo.front_photo} file_1="Foto frontal" commercial_name={merchantInfo.business_name}
                        />
                    )
                }
                {
                    step === 2 && (
                        <Step2 step={step} nextStep={() => validateAnswer(1)} decline={()=> declineDocument(1)} 
                            title={"Verificación del nombre y número de identificación con respecto al acta constitutiva"} url={merchantInfo.identification}
                            file_1="Identificación" file_2="Acta constitutiva" url_2={merchantInfo.constitutive_act}
                        />
                    )
                }
                {
                    step === 3 && (
                        <Step2 step={step} nextStep={() => validateAnswer(2)} decline={()=> declineDocument(2)} 
                            title={"Verificación de vigencia de la identificacion oficial"} url={merchantInfo.identification} file_1="Identificación"
                        />
                    )
                }
                {
                    step === 4 && (
                        <Step2 step={step} nextStep={() => validateAnswer(3)} decline={()=> declineDocument(3)} 
                            title={"Verificación de razón social en el acta constitutiva con el RIF"} url={merchantInfo.constitutive_act}
                            file_1="Acta constitutiva" file_2="RIF" url_2={merchantInfo.rif}
                        />
                    )
                }
                {
                    step === 5 && (
                        <Step2 step={step} nextStep={() => validateAnswer(4)} decline={()=> declineDocument(4)} 
                            title={"Vigencia de RIF"} file_1="RIF" url={merchantInfo.rif}
                        />
                    )
                }
                {
                    step === 6 && (
                        <Step2 step={step} nextStep={() => validateAnswer(5)} decline={()=> declineDocument(5)} 
                            title={"Verificación de razon social en el acta constitutiva y el número de registro de RIF"} url={merchantInfo.constitutive_act}
                            file_1="Acta constitutiva" file_2="RIF" url_2={merchantInfo.rif}
                        />
                    )
                }
                {
                    step === 7 && (
                        <Step2 step={step} nextStep={() => validateAnswer(6)} decline={()=> declineDocument(6)} 
                            title={"Declaracion de impuestos (debe ser del año anterior)"} file_1="Impuestos" url={merchantInfo.tax_return}
                        />
                    )
                }
                {
                    step === 8 && (
                        <Step2 step={step} nextStep={() => validateAnswer(7)} decline={()=> declineDocument(7)} 
                            title={"Dirección del comprobante de domicilio coincida con la del RIF"} url={merchantInfo.proof_address} file_1="Comprobante de domicilio"
                            file_2="RIF" url_2={merchantInfo.rif}
                        />
                    )
                }
                {
                    step === 9 && (
                        <Step2 step={step} nextStep={() => validateAnswer(8)} decline={()=> declineDocument(8)} 
                            title={"Verificación de metadatos coincida con la foto de la ubicación validada por la app"} url={merchantInfo.front_photo} file_1="Foto frontal"
                        />
                    )
                }
                {
                    step === 10 && (
                        <Step2 step={step} nextStep={() => validateAnswer(9)} decline={()=> declineDocument(9)} 
                            title={"Dominio sobre el establecimiento (fotos tomadas con libertad)"} url={merchantInfo.front_photo} file_1="Foto frontal"
                            url_2={merchantInfo.street_left_photo ? merchantInfo.street_left_photo : undefined} file_2="Foto de calle izquierda"
                            url_3={merchantInfo.street_right_photo ? merchantInfo.street_right_photo : undefined} file_3="Foto de calle derecha"
                            url_4={merchantInfo.inside_photo ? merchantInfo.inside_photo : undefined} file_4="Foto interior"
                        />
                    )
                }
                {
                    step === 11 && (
                        <DocumentsStatusView step={step} nextStep={() => validateAnswer(9)} title={"Resumen de Documentos"} validations={validations} />
                    )
                }
            </Box>
        </>
    )
} 

export default ValidateDocument;