import { Box, Grid, Textarea, Typography, useTheme } from "@mui/joy";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import PrimaryButton from "../UI/custom_elements/primaryButton";
import { useState } from "react";

const DeclineDocuments = ({close, index, updateValidation, nextStep}) => {

    const [reason, setReason] = useState("");

    const saveDecline = () => {
        updateValidation(index, reason)
        close();
        nextStep()
    }

    const theme = useTheme();

    return (
        <>
            <Box width={500}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Verificación no exitosa
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "150%",
                        color: theme.vars.palette.text.main,
                    }}>
                        Menciona las razónes por las cuales el documento es rechazado.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Textarea 
                        minRows={3} 
                        maxRows={3}
                        placeholder="Escribe aqui"
                        onChange={(e)=> setReason(e.target.value)}
                        value={reason}
                        sx={{
                            display: "flex",
                            padding: "8px 16px",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            color: theme.vars.palette.text.main,
                            gap: "8px",
                            borderRadius: "12px",
                            border: "2px solid #18E9E9",
                            backgroundColor: theme.vars.palette.background.body,  
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "120%",                
                        }} 
                    />
                </Box>
                <Box mt={2}>
                    <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                        <Grid md={1}>
                            <SecondaryButton click={()=> close()} text="Cancelar" full={true} />
                        </Grid>
                        <Grid md={1}>
                            <PrimaryButton click={() => saveDecline()} text="Guardar" full={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
} 

export default DeclineDocuments;