import React, { useState } from 'react';

import usePost from "../hooks/usePost"

const SessionContext = React.createContext();

export const SessionContextProvider = (props) => {

    const [session, setSession] = useState({
        email: "",
        type: "",
        token: ""
    });

    const { post } = usePost();

    const setActualSession = async (user) => {
        try{
            console.log("LOGIN")
            const data = await post("/admin/login", user)
            if(data && data.token){
                setSession({email: user.email,type: "admin", token: data.token });
                if (typeof window !== 'undefined' && window.localStorage) {
                    localStorage.setItem("User", JSON.stringify({email: user.email,type: "admin", token: data.token }));
                    return {email: user.email,type: "admin", token: data.token }
                }
                return {}
            }
            return {}
        }
        catch(error){
            throw error;
        }
    };

    const logOut = async () => {
        try{
            await post("/admin/logout", {}, session.token)
            if (typeof window !== 'undefined' && window.localStorage) {
                localStorage.removeItem("User");
            }
            setSession({
                email: "",
                role: "",
                token: "",
            });
        }
        catch{
            if (typeof window !== 'undefined' && window.localStorage) {
                localStorage.removeItem("User");
            }
            setSession({
                email: "",
                role: "",
                token: "",
            });
        }
    }

    return (
        <SessionContext.Provider
            value={{
                session,
                setActualSession,
                logOut,
                setSession
            }}
        >
            {props.children}
        </SessionContext.Provider>
    );
};

export default SessionContext;
