import { Box, Typography, useTheme } from "@mui/joy";
import HeadTable from "../../components/UI/custom_elements/headTable";
import DashboardTableOperator from "../../components/dashboardOperator/dashboardTableOperator";
import { useState } from "react";
import DashboardFilter from "../../components/dashboardOperator/filter";

const DashboardOperator = () =>{
    const theme = useTheme();
    const heads = ["Fecha de solicitud", "Nombre", "Email", "Nombre del negocio", ""];
    const [type, setType] = useState('pending')
    
    const change = (value)=> {
        setType(value)
    }

    return(
        <>
            <Box mx={5} my={2} sx={{width: "calc(100vw - 232px)"}}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Solicitudes de merchant activas
                    </Typography>
                </Box>
                <Box mt={4}>
                    <DashboardFilter change={change} />
                </Box>
                <Box mt={4}>
                    <HeadTable heads={heads} theme={theme} />
                </Box>
                <Box mt={1}>
                    <DashboardTableOperator type={type} theme={theme} columns={heads.length} />
                </Box>
            </Box>
        </>
    )
}

export default DashboardOperator;