import { Box, Grid, Typography } from "@mui/joy";
import Edit from "../icons/edit"
import Dialog from "../UI/custom_elements/dialog";
import NewUser from "./newUser";

import { useState } from "react";
import ChangeStatus from "./changeStatus";

const TableUsers = ({elements, theme, columns, fetchData}) =>{

    const [openNewUser, setOpenNewUser] = useState(false);
    const [openEditUser, setEditNewUser] = useState(false);
    const [changeS, setChangeS] = useState({
        show: false,
        status: "",
        id: ""
    })

    const [editUser, setEditUser] = useState();

    return(
        <>
            <Dialog title="Nuevo usuario" open={openNewUser} close={()=> setOpenNewUser(false)}>
                <NewUser edit={false} editUser={editUser} close={()=> setOpenNewUser(false)} fetchData={fetchData} />
            </Dialog>
            <Dialog title="Editar usuario" open={openEditUser} close={()=> setEditNewUser(false)}>
                <NewUser edit={true} editUser={editUser} close={()=> setEditNewUser(false)} fetchData={fetchData} />
            </Dialog>
            <Dialog title="Cambiar Estatus" open={changeS.show} close={()=> setChangeS({show: false, status: "", id: ""})}>
                <ChangeStatus userId={changeS.id} status={changeS.status} close={()=> setChangeS({show: false, status: "", id: ""})} fetchData={fetchData} />
            </Dialog>
            {
                elements.map((element, index)=>{
                    return(
                        <Grid container spacing={2} columns={12} display="flex" key={index} sx={{flexGrow: 1}}>
                            <Grid display="flex" justifyContent="center" alignItems="center" md={11}>
                                <Grid 
                                    container 
                                    spacing={2} 
                                    mb={2}
                                    columns={columns} 
                                    sx={{
                                        flexGrow: 1,
                                        borderRadius: "12px",
                                        padding: "8px",
                                        backgroundColor: theme.vars.palette.card
                                    }}>
                                        <Grid md={1}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Box mr={2}>
                                                    {
                                                        element.status === "active" ? (
                                                            <Box sx={{cursor: "pointer"}} onClick={()=> {setChangeS({show: true, status: "inactive", id: element._id})}}>
                                                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6" cy="6.5" r="6" fill="#1AF565"/>
                                                                </svg>
                                                            </Box>
                                                        ) : (
                                                            <Box sx={{cursor: "pointer"}} onClick={()=> {setChangeS({show: true, status: "active", id: element._id})}}>
                                                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6" cy="6.5" r="6" fill="#F20E25"/>
                                                                </svg>
                                                            </Box>
                                                        )
                                                    }
                                                </Box>
                                                <Box maxWidth={180}>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "150%",
                                                        color: theme.vars.palette.text.main,
                                                        whiteSpace: "nowra",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }}>
                                                        {element.id}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                                whiteSpace: "nowra",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                {element.first_name}
                                            </Typography>
                                        </Grid>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                                whiteSpace: "nowra",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                {element.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                                whiteSpace: "nowra",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                {element.email}
                                            </Typography>
                                        </Grid>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                            }}>
                                                {element.country_code} {element.mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                            }}>
                                                
                                            </Typography>
                                        </Grid>
                                </Grid>
                            </Grid>
                            <Grid display="flex" justifyContent="center"  md={1}>
                                <Grid 
                                    container 
                                    spacing={2} 
                                    mb={2}
                                    columns={1}
                                    onClick={() => {setEditUser(element); setEditNewUser(true)}} 
                                    sx={{
                                        flexGrow: 1,
                                        borderRadius: "12px",
                                        padding: "8px",
                                        backgroundColor: theme.vars.palette.card,
                                        mx: 1,
                                        cursor: "pointer"
                                    }}>
                                        <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                            <Edit />
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default TableUsers;