const Add = () => {
    return(
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00016 15.1667C11.6668 15.1667 14.6668 12.1667 14.6668 8.50004C14.6668 4.83337 11.6668 1.83337 8.00016 1.83337C4.3335 1.83337 1.3335 4.83337 1.3335 8.50004C1.3335 12.1667 4.3335 15.1667 8.00016 15.1667Z" stroke="#082B39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.3335 8.5H10.6668" stroke="#082B39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 11.1667V5.83337" stroke="#082B39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default Add;