import { Box, Button, Grid, Input, Option, Select, Typography, useTheme } from "@mui/joy";
import Snackbar from '@mui/joy/Snackbar';
import CustomInput from "../UI/custom_elements/customInput"
import PrimaryButton from "../UI/custom_elements/primaryButton";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import { useContext, useRef, useState } from "react";
import SessionContext from "../../store";
import MapClient from "./mapClient";
import moment from "moment";
import AutocompleteClient from "./autocomplete";
import usePut from "../../hooks/usePut";

const EditClient = ({ editClient, close, fetchData }) => {
    const theme = useTheme();
    const [client, setClient] = useState(editClient);

    const [errorMessage, setErrorMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState(false);

    const letterRef = useRef(null);
    const imageRef = useRef(null);
    const identificationRef = useRef(null);
    const [files, setFiles] = useState({
        file_1: "",
        file_2: "",
        file_3: "",
    })

    const { session } = useContext(SessionContext);

    const { put, isLoading: editLoading } = usePut();

    const save = async () => {
        let body = {
            profile: {},
            evidence: {
                photo: files.file_1,
                identification: files.file_2,
                letter: files.file_3
            }
        }
        if(client.level === 1){
            if (editClient.first_name !== client.first_name) {
                body.profile.first_name = client.first_name;
            }
            if (editClient.middle_name !== client.middle_name) {
                body.profile.middle_name = client.middle_name;
            }
            if (editClient.father_last_name !== client.father_last_name) {
                body.profile.father_last_name = client.father_last_name;
            }
            if (editClient.mother_last_name !== client.mother_last_name) {
                body.profile.mother_last_name = client.mother_last_name;
            }
            if (editClient.birthday !== client.birthday) {
                body.profile.birthday = client.birthday;
            }
        }
        if (editClient.mobile !== client.mobile) {
            body.profile.mobile = client.mobile;
        }
        if (editClient.country_code !== client.country_code) {
            body.profile.country_code = client.country_code;
        }
        if (editClient.status !== client.status) {
            body.profile.status = client.status;
        }
        if (client.level > 1) {
            if (editClient.email !== client.email) {
                body.profile.email = client.email;
            }
            if(client.level > 2){
                if (!body.profile.merchant) {
                    body.merchant = {};
                }
                if (client.merchant_request.business_name) {
                    if(editClient.merchant_request.business_name !== client.merchant_request.business_name)
                        body.merchant.business_name = client.merchant_request.business_name;
                }
                if (client.merchant_request && client.merchant_request.latitude && client.merchant_request.longitude) {
                    if(editClient.merchant_request.latitude !== client.merchant_request.latitude)
                        body.merchant.latitude =client.merchant_request.latitude;
                    if(editClient.merchant_request.longitude !== client.merchant_request.longitude)
                        body.merchant.longitude = client.merchant_request.longitude;
                }
            }
        }
        try {
            const response = await put(process.env.REACT_APP_URL_API + "/admin/clients/" + editClient._id, body, session.token);
            if (response) {
                fetchData();
                close();
            }
            else {
                setErrorMessage(true);
            }
        }
        catch (error) {
            console.log(error);
            setErrorMessage(true);
        }
    }

    const formatDate = (isoDate) => {
        return moment(isoDate).format('YYYY-MM-DD');
    }


    const handleFileInputChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64Data = e.target.result.split(',')[1]; // Split off the base64 header
                //const fileType = file.type.split('/')[1]; // Get the file extension
                // Update the state with the base64 data
                setFiles(prevState => ({
                    ...prevState,
                    [`file_${index}`]: `data:${file.type};base64,${base64Data}`
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const getLocation = (latitude, longitude) => {
        setClient(prevClient => ({
            ...prevClient,
            merchant_request: {
                ...prevClient.merchant_request,
                latitude: latitude,
                longitude: longitude
            }
        }));
    }

    const compareObjects = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
    
        if (keys1.length !== keys2.length) {
            return false;
        }
    
        for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    
        return true;
    };

    const desactived = () => {
        for (const key in files) {
            if (files.hasOwnProperty(key) && files[key] === "") {
                return true;
            }
        }
        if (client.level === 1) {
            if (client.first_name === "" || client.father_last_name === "" || client.mother_last_name === "" || client.mobile === "" || client.birthday === "" || client.status === "" || client.email === "") {
                return true;
            }
        }
        if (client.level > 1) {
            if (client.mobile === "" || client.status === "" || client.email === "" || client.merchant_request.business_name === "") {
                return true;
            }
        }
        if (compareObjects(client, editClient)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Snackbar
                open={errorMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                variant="solid"
                color="danger"
                autoHideDuration={4000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setErrorMessage(false);
                }}
            >
                Ocurrió un problema, por favor valide que su información sea valida.
            </Snackbar>
            <Snackbar
                open={infoMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                variant="solid"
                color="neutral"
                autoHideDuration={4000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setInfoMessage(false);
                }}
            >
                Complete la información antes de continuar / cambie al menos un campo
            </Snackbar>
            <Box height={500} width={700}>
                <Box sx={{ overflowY: "auto", maxHeight: "500px", overflowX: "hidden" }}>
                    <Grid container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                        {
                            client.level === 1 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Nombre (s)"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Nombre"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                first_name: value
                                            })
                                            )}
                                        value={client.first_name ? client.first_name : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level === 1 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Segundo nombre"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Segundo nombre"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                middle_name: value
                                            })
                                            )}
                                        value={client.middle_name ? client.middle_name : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level === 1 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Apellido paterno"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Apellido paterno"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                father_last_name: value
                                            })
                                            )}
                                        value={client.father_last_name ? client.father_last_name : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level === 1 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Apellido materno"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Apellido materno"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                mother_last_name: value
                                            })
                                            )}
                                        value={client.mother_last_name ? client.mother_last_name : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level > 0 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Email"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Email"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                email: value
                                            })
                                            )}
                                        value={client.email ? client.email : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level >= 0 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Lada"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Lada"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                country_code: value
                                            })
                                            )}
                                        value={client.country_code ? client.country_code : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level >= 0 && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Télefono"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Télefono"
                                        width="100%"
                                        setValue={(value) =>
                                            setClient(prevUser => ({
                                                ...prevUser,
                                                mobile: value
                                            })
                                            )}
                                        value={client.mobile ? client.mobile : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level === 1 && client.birthday && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Fecha de nacimiento"}
                                    </Typography>
                                    <Input
                                        type="date"
                                        onChange={(e) => setClient(prevUser => ({
                                            ...prevUser,
                                            birthday: e.target.value
                                        }))}
                                        value={formatDate(client.birthday)}
                                        sx={{
                                            display: "flex",
                                            padding: "8px 16px",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            alignItems: "center",
                                            color: theme.vars.palette.text.main,
                                            gap: "8px",
                                            borderRadius: "12px",
                                            border: "2px solid #18E9E9",
                                            backgroundColor: "transparent",
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                        }}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level > 0 && client.status && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Estatus"}
                                    </Typography>
                                    <Select value={client.status} placeholder="Estatus" onChange={(e, newValue) => {
                                        setClient(prevUser => ({
                                            ...prevUser,
                                            status: newValue
                                        }))
                                    }}
                                        sx={{
                                            display: "flex",
                                            padding: "8px 16px",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            alignItems: "center",
                                            color: theme.vars.palette.text.main,
                                            gap: "8px",
                                            borderRadius: "12px",
                                            border: "2px solid #18E9E9",
                                            backgroundColor: theme.vars.palette.background.body,
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                        }}

                                    >
                                        <Option value={"active"}>Activo</Option>
                                        <Option value={"suspended"}>Suspendido</Option>
                                        <Option value={"temporary-blocked"}>Bloqueado temporalmente</Option>
                                    </Select>
                                </Grid>
                            )
                        }
                        {
                            client.level > 2 && (editClient.merchant_request.business_name) && (
                                <Grid md={1}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Nombre del negocio"}
                                    </Typography>
                                    <CustomInput
                                        placeholder="Nombre del negocio"
                                        width="100%"
                                        setValue={(value) => 
                                            setClient(prevClient => ({
                                                ...prevClient,
                                                merchant_request: {
                                                    ...prevClient.merchant_request,
                                                    business_name: value,
                                                }
                                            })
                                        )}
                                        value={client.merchant_request.business_name ? client.merchant_request.business_name : ""}
                                    />
                                </Grid>
                            )
                        }
                        {
                            client.level > 2 && client.merchant_request && client.merchant_request.latitude && client.merchant_request.longitude && (
                                <Grid md={2}>
                                    <Typography mb={0.5} sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                    }}>
                                        {"Ubicación del negocio"}
                                    </Typography>
                                    <Box mb={4}>
                                        <AutocompleteClient getLocation={getLocation} center={{ lat: client.merchant_request.latitude, lng: client.merchant_request.longitude }} coordinates={[{ lat: client.merchant_request.latitude, lng: client.merchant_request.longitude }]} />
                                    </Box>
                                    <MapClient getLocation={getLocation} center={{ lat: client.merchant_request.latitude, lng: client.merchant_request.longitude }} coordinates={[{ lat: client.merchant_request.latitude, lng: client.merchant_request.longitude }]} />
                                </Grid>
                            )
                        }
                        <Grid md={2}>
                            <Typography mb={0.5} sx={{
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "150%",
                                color: theme.vars.palette.text.main,
                            }}>
                                {"Cargar foto"}
                            </Typography>
                            <Button onClick={() => imageRef.current.click()} sx={{
                                display: "inline-flex",
                                width: "100%",
                                minWidth: "120px",
                                maxWidth: "100%",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "8px",
                                borderRadius: "12px",
                                backgroundColor: files.file_1 ? theme.vars.palette.primary["500"] : theme.vars.palette.primary["100"],
                                color: theme.vars.palette.text.secondary,
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",
                            }}>
                                <Box mt={0.5}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.00008 11.8334V7.83337L4.66675 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 7.83337L7.33333 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671V10.5C14.6666 13.8334 13.3333 15.1667 9.99992 15.1667H5.99992C2.66659 15.1667 1.33325 13.8334 1.33325 10.5V6.50004C1.33325 3.16671 2.66659 1.83337 5.99992 1.83337H9.33325" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671H11.9999C9.99992 7.16671 9.33325 6.50004 9.33325 4.50004V1.83337L14.6666 7.16671Z" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Box>
                                {files.file_1 ? "Evidencia cargada" : "Cargar evidencia"}
                            </Button>
                            <input
                                ref={imageRef}
                                type="file"
                                accept="image/jpeg, image/png"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileInputChange(e, 1)}
                            />
                        </Grid>
                        <Grid md={2}>
                            <Typography mb={0.5} sx={{
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "150%",
                                color: theme.vars.palette.text.main,
                            }}>
                                {"Cargar identificación"}
                            </Typography>
                            <Button onClick={() => identificationRef.current.click()} sx={{
                                display: "inline-flex",
                                width: "100%",
                                minWidth: "120px",
                                maxWidth: "100%",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "8px",
                                borderRadius: "12px",
                                backgroundColor: files.file_2 ? theme.vars.palette.primary["500"] : theme.vars.palette.primary["100"],
                                color: theme.vars.palette.text.secondary,
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",
                            }}>
                                <Box mt={0.5}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.00008 11.8334V7.83337L4.66675 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 7.83337L7.33333 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671V10.5C14.6666 13.8334 13.3333 15.1667 9.99992 15.1667H5.99992C2.66659 15.1667 1.33325 13.8334 1.33325 10.5V6.50004C1.33325 3.16671 2.66659 1.83337 5.99992 1.83337H9.33325" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671H11.9999C9.99992 7.16671 9.33325 6.50004 9.33325 4.50004V1.83337L14.6666 7.16671Z" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Box>
                                {files.file_2 ? "Evidencia cargada" : "Cargar evidencia"}
                            </Button>
                            <input
                                ref={identificationRef}
                                type="file"
                                accept=".pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileInputChange(e, 2)}
                            />
                        </Grid>
                        <Grid md={2}>
                            <Typography mb={0.5} sx={{
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "150%",
                                color: theme.vars.palette.text.main,
                            }}>
                                {"Cargar carta"}
                            </Typography>
                            <Button
                                onClick={() => letterRef.current.click()}
                                sx={{
                                    display: "inline-flex",
                                    width: "100%",
                                    minWidth: "120px",
                                    maxWidth: "100%",
                                    padding: "8px 16px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "8px",
                                    borderRadius: "12px",
                                    backgroundColor: files.file_3 ? theme.vars.palette.primary["500"] : theme.vars.palette.primary["100"],
                                    color: theme.vars.palette.text.secondary,
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "120%",
                                }}
                            >
                                <Box mt={0.5}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.00008 11.8334V7.83337L4.66675 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 7.83337L7.33333 9.16671" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671V10.5C14.6666 13.8334 13.3333 15.1667 9.99992 15.1667H5.99992C2.66659 15.1667 1.33325 13.8334 1.33325 10.5V6.50004C1.33325 3.16671 2.66659 1.83337 5.99992 1.83337H9.33325" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6666 7.16671H11.9999C9.99992 7.16671 9.33325 6.50004 9.33325 4.50004V1.83337L14.6666 7.16671Z" stroke="#031D27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Box>
                                {files.file_3 ? "Evidencia cargada" : "Cargar evidencia"}
                            </Button>
                            <input
                                ref={letterRef}
                                type="file"
                                accept=".pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileInputChange(e, 3)}
                            />
                        </Grid>
                    </Grid>
                    <Grid mt={2} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid md={1}>
                            <SecondaryButton click={() => close()} text="Cancelar" full={true} />
                        </Grid>
                        <Grid md={1}>
                            <PrimaryButton desactived={desactived()} loading={editLoading} click={() => { desactived() === true ? setInfoMessage(true) : save() }} text="Guardar" full={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default EditClient;