import { useState } from "react";
import axios from "axios";

const usePut = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const put = async (url, requestData, token = null) => {
    setIsLoading(true);

    const config = {
      headers: {}
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await axios.put(url, requestData, config);
      return response.data;
    } catch (err) {
      setError(err);
      if(err.response.status === 401){
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.removeItem("User");
        }
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { error, isLoading, put };
};

export default usePut;
