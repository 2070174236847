import { Box, Grid } from "@mui/joy";
import PrimaryButton from "../UI/custom_elements/primaryButton";
import { useEffect, useState } from "react";

const DashboardFilter = ({change}) => {

    const [filter, setFilter] = useState([
        {
            active: true,
            name: "Pendientes"            
        },
        {
            active: false,
            name: "Rechazados"
        },
        {
            active: false,
            name: "Aceptados"
        },
    ]);

    const findActiveIndex = (filterArray) => {
        for (let i = 0; i < filterArray.length; i++) {
          if (filterArray[i].active) {
            return i;
          }
        }
        return -1; 
    };

    const active = (period) => {
        setFilter((prev)=>{
            let newActive = JSON.parse(JSON.stringify(prev));
            let array = newActive.map((element) =>{
                if(element.name === period.name){
                    return {active: true, name: element.name}
                }
                else{
                    return {active: false, name: element.name}
                }
            })
            return array;
        });
    }
    
    useEffect(()=>{
        const index =  findActiveIndex(filter);
        const type = index === 0 ? 'pending' : index === 1 ? 'rejected' : 'approved'
        change(type);
    }, [filter, change])

    return (
        <>
            <Grid container columns={2} spacing={2} sx={{flexGrow: 1, maxWidth: "calc(100vw - 300px)", height: "100%", overflowY:"hidden", overflowX: "hidden"}}>
                <Grid lg={2}>
                    <Box display="flex" ml={4} mb={2}>
                        {
                            filter.map((period, index)=> {
                                return(
                                    <Box mr={1} key={index}>
                                        <PrimaryButton desactived={period.active} full={true} click={()=> active(period)} text={period.name} />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    )
} 

export default DashboardFilter;