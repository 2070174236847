import { Box, Typography, useTheme } from "@mui/joy";

const TitleDocument = ({step, title}) => {
    
    const theme = useTheme();

    return (
        <Box>
            <Box>
                <Typography sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "150%",
                    color: theme.vars.palette.text.main,
                }}>
                    Paso {step} de 11
                </Typography>
            </Box>
            <Box my={3}>
                <Typography sx={{
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    color: theme.vars.palette.primary["500"],
                }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    )
} 

export default TitleDocument;