const DocumentIcon = () => {
    return(
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 7.83337V11.8334L7.33333 10.5" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.99996 11.8333L4.66663 10.5" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6667 7.16671V10.5C14.6667 13.8334 13.3334 15.1667 10 15.1667H6.00004C2.66671 15.1667 1.33337 13.8334 1.33337 10.5V6.50004C1.33337 3.16671 2.66671 1.83337 6.00004 1.83337H9.33337" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.6667 7.16671H12C10 7.16671 9.33337 6.50004 9.33337 4.50004V1.83337L14.6667 7.16671Z" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default DocumentIcon;