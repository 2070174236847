import { Modal, ModalClose, ModalDialog, Typography, useTheme } from "@mui/joy";

const Dialog = ({children, title, open, close}) => {

    const theme = useTheme();

    return(
        <Modal open={open} onClose={() => close()}>
            <ModalDialog
                sx={{
                    backgroundColor: theme.vars.palette.background.body,
                    zIndex: 1,
                }}
                variant="plain"
            >
                <ModalClose />
                <Typography
                    sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}
                >
                    {title}
                </Typography>
                {
                    children
                }
            </ModalDialog>
        </Modal>
    )
}

export default Dialog;