import { Box, Grid, Typography, useTheme } from "@mui/joy";
import TitleDocument from "./titleDocument";
import PrimaryButton from "../UI/custom_elements/primaryButton";

const DocumentsStatusView = ({ step, nextStep, title, validations }) => {
    const theme = useTheme();

    // Función para obtener el nombre anterior del documento
    const getOldDocumentName = (currentName) => {
        const documentNameMap = {
            "business_name": "Verificación del nombre del comercio",
            "identity_name_number": "Verificación del nombre y número de identificación con respecto al acta constitutiva",
            "identity_expiration": "Verificación de vigencia de la identificación oficial",
            "rif_business_name": "Verificación de razón social en el acta constitutiva con el RIF",
            "rif_expiration": "Vigencia de RIF",
            "rif_business_name_number": "Verificación de razón social en el acta constitutiva y el número de registro de RIF",
            "tax_declaration": "Declaración de impuestos (debe ser del año anterior)",
            "address_rif": "Dirección del comprobante de domicilio coincida con la del RIF",
            "location_metadata": "Verificación de metadatos coincida con la foto de la ubicación validada por la app",
            "business_ownership": "Dominio sobre el establecimiento (fotos tomadas con libertad)",
        };
        return documentNameMap[currentName] || currentName;
    };

    return (
        <>
            <TitleDocument step={step} title={title} />
            <Box sx={{
                borderRadius: "24px",
                padding: "24px",
                backgroundColor: theme.vars.palette.card,
                maxHeight: "400px",
                overflowY: "auto"
            }}>
                <Box>
                    {validations.map((validation, index) => (
                        <Box key={index} sx={{
                            borderRadius: "24px",
                            padding: "24px",
                            backgroundColor: theme.vars.palette.background.body,
                            mt: 2,
                        }}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    Documento: {getOldDocumentName(validation.document)}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                {validation.approved ? (
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "150%",
                                        color: 'green',
                                    }}>
                                        Aprobado
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "150%",
                                            color: 'red',
                                        }}>
                                            Rechazado
                                        </Typography>
                                        {validation.reason && (
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                            }}>
                                                Razón: {validation.reason}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box mt={2}>
                <Grid container columns={1} spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid md={1}>
                        <PrimaryButton click={() => nextStep()} text={"Validar"} full={true} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DocumentsStatusView;