import { Alert, Box, Option, Select, Typography, useTheme } from "@mui/joy";
import HeadTable from "../../components/UI/custom_elements/headTable";
import TableTransactions from "../../components/transactions/tableTransactions";
import DatePicker from "../../components/UI/custom_elements/datePicker";
import ArrowRight from "../../components/icons/arrowRight";
import { useContext, useState } from "react";
import useGet from "../../hooks/useGet";
import SessionContext from "../../store";
import CustomInput from "../../components/UI/custom_elements/customInput"
import PrimaryButton from "../../components/UI/custom_elements/primaryButton";
import { Loading2 } from "../../components/UI/custom_elements/loading2";

const Transactions = () =>{
    const theme = useTheme();
    const heads = ["ID", "Fecha", "Tipo","Subtipo","Tipo ATM", "Monto", "Emisor", "Beneficiario"];

    const [filter, setFilter] = useState({
        start: null,
        end: null,
        type: null,
        sub_type: null,
        atm_type:null,
        term: ""
    });

    const {error, isLoading, get} = useGet();
    const { session } = useContext(SessionContext);

    const [transactions, setTransactions] = useState([]);
    const [warningMessage, setWarningMessage] = useState(false)
    
    if(error){
        console.log(error);
    }

    const clean = () => {
        setFilter(prevUser => ({
            ...prevUser,
            type: null,
            sub_type: null,
            atm_type:null,
            term: ""
        }));
    }
    
    const fetchData = async () => {
        try {
          if (filter.start !== null && filter.end !== null) {
            let queryString = "/admin/transactions?";
      
            if (filter.start !== "") {
              queryString += `from=${filter.start}`;
            }
      
            if (filter.end !== "") {
              queryString += `${filter.start !== "" ? "&" : ""}to=${filter.end}`;
            }
      
            if (filter.type !== null) {
              queryString += `${filter.start !== "" || filter.end !== "" ? "&" : ""}type=${filter.type}`;
            }
      
            if (filter.sub_type !== null) {
              queryString += `${filter.start !== "" || filter.end !== "" || filter.type !== null ? "&" : ""}sub_type=${filter.sub_type}`;
            }
      
            if (filter.atm_type !== null) {
              queryString += `${filter.start !== "" || filter.end !== "" || filter.type !== null || filter.sub_type !== null ? "&" : ""}atm_type=${filter.atm_type}`;
            }
      
            if (filter.term !== "") {
              queryString += `${filter.start !== "" || filter.end !== "" || filter.type !== null || filter.sub_type !== null || filter.atm_type !== null ? "&" : ""}term=${filter.term}`;
            }
      
            const result = await get(queryString, session.token);
            if(result){
                setTransactions(result);
            }
          } else {
            setTimeout(() => {
              setWarningMessage(false);
            }, 4000);
            setWarningMessage(true);
          }
        } catch (error) {
          console.log(error);
        }
      };

    return(
        <>
            <Box position="relative" mx={5} my={2} sx={{width: "calc(100vw - 232px)"}}>
                {
                    warningMessage && (
                        <Box position="absolute" bottom={0} left="30%">
                            <Alert variant="outlined" severity="error">Para ver resultados tiene que seleccionar un rango de fechas</Alert>
                        </Box>
                    )
                }
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Transacciones
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{maxWidth: "calc(100vw - 232px)", flexWrap: "wrap"}}>
                    <Box my={1}>
                        <DatePicker 
                            theme={theme} 
                            setValue={(value) =>   
                                setFilter(prevUser => ({
                                    ...prevUser,
                                    start: value 
                                })
                            )} 
                            value={filter.start} 
                        />
                    </Box>
                    <Box my={1} mx={1}>
                        <ArrowRight theme={theme} />
                    </Box>
                    <Box my={1} mr={2}>
                        <DatePicker 
                            theme={theme} 
                            setValue={(value) =>   
                            setFilter(prevUser => ({
                                    ...prevUser,
                                    end: value 
                                })
                            )} 
                            value={filter.end} 
                        />
                    </Box>
                    <Box my={1} mr={2}>
                        <Select  value={filter.type} width="200px" placeholder="Elige un Tipo" onChange={(e, newValue)=> {
                            console.log(newValue)
                            setFilter(prevUser => ({
                                ...prevUser,
                                type: newValue
                            }))
                        }} 
                            sx={{
                                display: "flex",
                                padding: "8px 16px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                color: theme.vars.palette.text.main,
                                gap: "8px",
                                borderRadius: "12px",
                                border: "2px solid #18E9E9",
                                backgroundColor: theme.vars.palette.background.body,  
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",                
                            }} 
                        
                        >
                            <Option value={"transfer"}>Muney a muney</Option>
                            <Option value={"commission"}>Comisión de merchant</Option>
                            <Option value={"muney-commission"}>Comisión de muney</Option>
                            <Option value={"withholding"}>Retención</Option>
                            <Option value={"⁠crypto-withdrawal"}>Retiro de criptos</Option>
                        </Select>
                    </Box>
                    <Box my={1} mr={2}>
                        <Select value={filter.sub_type} width="200px" placeholder="Elige un Subtipo" onChange={(e, newValue)=> {
                            console.log(newValue)
                            setFilter(prevUser => ({
                                ...prevUser,
                                sub_type: newValue
                            }))
                        }} 
                            sx={{
                                display: "flex",
                                padding: "8px 16px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                color: theme.vars.palette.text.main,
                                gap: "8px",
                                borderRadius: "12px",
                                border: "2px solid #18E9E9",
                                backgroundColor: theme.vars.palette.background.body,  
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",                
                            }} 
                        
                        >
                            <Option value={"outgoing"}>Saliente</Option>
                            <Option value={"incoming"}>Entrante</Option>
                            <Option value={"muney-commission"}>Comisión de muney</Option>
                            <Option value={"⁠transaction-fee"}>Comisión por transacción</Option>
                            <Option value={"⁠⁠platform-fee"}>Comisión por uso de la plataforma</Option>
                        </Select>
                    </Box>
                    <Box my={1} mr={2}>
                        <Select value={filter.atm_type} width="200px" placeholder="Elige un tipo ATM" onChange={(e, newValue)=> {
                            console.log(newValue)
                            setFilter(prevUser => ({
                                ...prevUser,
                                atm_type: newValue
                            }))
                        }} 
                            sx={{
                                display: "flex",
                                padding: "8px 16px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                color: theme.vars.palette.text.main,
                                gap: "8px",
                                borderRadius: "12px",
                                border: "2px solid #18E9E9",
                                backgroundColor: theme.vars.palette.background.body,  
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",                
                            }} 
                        
                        >
                            <Option value={"deposit"}>Transacción de depósito</Option>
                            <Option value={"withdrawal"}>Transacción de retiro</Option>
                        </Select>
                    </Box>
                    <Box my={1} mr={2}>
                        <PrimaryButton click={()=> clean()} text="Limpiar filtros" />
                    </Box>
                </Box>
                <Box display="flex" mt={2}>
                    <CustomInput 
                        placeholder="Busqueda General" 
                        width="100%" 
                        setValue={(value) =>   
                            setFilter(prevUser => ({
                                ...prevUser,
                                term: value 
                            })
                        )} 
                        value={filter.term}
                    />
                    <Box ml={2}>
                        <PrimaryButton click={()=> fetchData()} text="Buscar" add={true} />
                    </Box>
                </Box>
                <Box mt={4}>
                    <HeadTable heads={heads} theme={theme} />
                </Box>
                <Box mt={4}>
                    {
                        isLoading ? (
                            <Loading2 />
                        ) : (
                            <TableTransactions elements={transactions} theme={theme} columns={heads.length} />
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default Transactions;