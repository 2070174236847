import { Box, Grid, Typography, useTheme } from "@mui/joy";
import HeadTable from "../../components/UI/custom_elements/headTable";
import TableUsers from "../../components/users/tableUsers";
import PrimaryButton from "../../components/UI/custom_elements/primaryButton";
import Dialog from "../../components/UI/custom_elements/dialog"
import NewUser from "../../components/users/newUser";

import { useContext, useEffect, useState } from "react";
import useGet from "../../hooks/useGet"
import { Loading2 } from "../../components/UI/custom_elements/loading2";
import SessionContext from "../../store";

const Users = () =>{

    const theme = useTheme();
    const heads = ["ID de usuario", "Nombre (s)", "Apellido (s)", "Mail", "Teléfono"];

    const [openNewUser, setOpenNewUser] = useState(false);

    const [users, setUsers] = useState([]);

    const { session } = useContext(SessionContext);

    const {error, isLoading, get} = useGet();

    if(error){
        console.log(error);
    }

    const fetchData = async () => {
        try {
            if(session.token){
                const result = await get("/admin/users", session.token);
                if(result){
                    setUsers(result);
                }
            }

        } catch (error) {
          console.log(error);
        }
    };

    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return(
        <>
            <Dialog title="Nuevo usuario" open={openNewUser} close={()=> setOpenNewUser(false)}>
                <NewUser close={()=> setOpenNewUser(false)} fetchData={fetchData} />
            </Dialog>
            <Box mx={5} my={2} sx={{width: "calc(100vw - 232px)"}}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Usuarios
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="end">
                    <PrimaryButton text="Nuevo usuario" add={true} click={()=> setOpenNewUser(true)} />
                </Box>
                <Box mt={4}>
                    <Grid container spacing={2} columns={12} display="flex" sx={{flexGrow: 1}}>
                        <Grid display="flex" md={11}>
                            <HeadTable heads={heads} theme={theme} />
                        </Grid>
                        <Grid md={1}></Grid>
                    </Grid>
                </Box>
                <Box mt={4}>
                    {
                        isLoading ? (
                            <Loading2 />
                        ) : (
                            <TableUsers elements={users} theme={theme} columns={heads.length} fetchData={fetchData} />
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default Users;