import { Box, Button, useTheme } from "@mui/joy";
import Add from "../../icons/add"

const Loading = () =>{

    const theme = useTheme();

    return (
        <Box display="flex" justifyContent="center" overflow="hidden"
            sx={{
                width: "20px",
                height: "20px",
                mx: 1,
            }}
        >
            <svg
                aria-hidden="true"
                style={{
                    fill: "#9ca3af",
                    color: "#EDF2F7",
                    animation: 'spin 1s linear infinite',
                }}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2319 16.778C10.3291 17.3917 9.90965 17.9754 9.28864 17.9954C8.01444 18.0363 6.74156 17.8061 5.55585 17.3149C4.02239 16.6797 2.69445 15.6329 1.71885 14.2901C0.743241 12.9473 0.157971 11.3608 0.027744 9.70613C-0.102483 8.05145 0.227407 6.39298 0.980941 4.91409C1.73448 3.43519 2.8823 2.19348 4.29751 1.32624C5.71273 0.458995 7.3402 2.9673e-08 9 0C10.6598 -2.9673e-08 12.2873 0.458995 13.7025 1.32624C14.7968 1.99682 15.7312 2.89128 16.4471 3.94617C16.796 4.46028 16.5703 5.14275 16.0167 5.42482V5.42482C15.4631 5.7069 14.7926 5.47939 14.4226 4.98026C13.9097 4.28843 13.2671 3.69827 12.5269 3.24468C11.4655 2.59425 10.2449 2.25 9 2.25C7.75515 2.25 6.53454 2.59425 5.47313 3.24468C4.41172 3.89511 3.55086 4.82639 2.98571 5.93556C2.42056 7.04473 2.17314 8.28859 2.27081 9.5296C2.36848 10.7706 2.80743 11.9604 3.53914 12.9676C4.27084 13.9747 5.26679 14.7598 6.41689 15.2362C7.21892 15.5684 8.07412 15.7414 8.93527 15.7497C9.55656 15.7557 10.1347 16.1644 10.2319 16.778V16.778Z"
                    fill={theme.palette.neutral[500]}
                />
            </svg>
        </Box>
    )
}

const PrimaryButton = ({text, add = false, full = false, click = () => {}, desactived = false, loading = false}) =>{

    const theme = useTheme();

    return (
        <>
            <Button onClick={()=> {click()}} startDecorator={ add ? <Add /> : undefined} sx={{
                display: "inline-flex",
                width:"100%",
                minWidth: "120px",
                maxWidth: full ? "100%" : "200px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: "12px",
                backgroundColor: desactived ? theme.vars.palette.primary["100"] : theme.vars.palette.primary["500"],
                color: theme.vars.palette.text.secondary,
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
            }}>
                {text} {loading && <Box mr={2}><Loading /></Box>}
            </Button>
        </>
    )
}

export default PrimaryButton;