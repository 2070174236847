import { Button, useTheme } from "@mui/joy";
import Add from "../../icons/add"
import DocumentIcon from "../../icons/documents";

const SecondaryButton = ({text, add = false, document = false, full = false, click = () => {}, table = false}) =>{

    const theme = useTheme();

    return (
        <>
            <Button onClick={()=> click()} 
                startDecorator={ add ? <Add /> : <></>} 
                endDecorator={document ? <DocumentIcon /> : <></>} 
                sx={{
                    display: "inline-flex",
                    width:"100%",
                    minWidth: "120px",
                    maxWidth: full ? "100%" : "200px",
                    padding: "8px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "12px",
                    backgroundColor: !table ? theme.vars.palette.background.sideBar : theme.vars.palette.background.body,
                    color: theme.vars.palette.text.main,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                }}
            >
                {text}
            </Button>
        </>
    )
}

export default SecondaryButton;