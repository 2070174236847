import { Box, Grid, Typography, useTheme } from "@mui/joy";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import PrimaryButton from "../UI/custom_elements/primaryButton";

const ValidateAnswer = ({close, nextStep, approveDocument, index}) => {

    const save = () => {
        close();
        approveDocument(index)
        nextStep();
    }

    const theme = useTheme();

    return (
        <>
            <Box width={500}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Confirmo que se validó correctamente el paso
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                        <Grid md={1}>
                            <SecondaryButton click={()=> close()} text="Cancelar" full={true} />
                        </Grid>
                        <Grid md={1}>
                            <PrimaryButton click={() => save()} text="Aceptar" full={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
} 

export default ValidateAnswer;