import { Input } from "@mui/joy";

const DatePicker = ({theme, setValue, value}) => {
    return (
        <Input
            type="date"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            slotProps={{
                input: {
                    min: '2018-06-07T00:00',
                    max: '2018-06-14T00:00',
                },
            }}
            sx={{
                display: "flex",
                padding: "8px 16px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                color: theme.vars.palette.text.main,
                gap: "8px",
                borderRadius: "12px",
                border: "2px solid #18E9E9",
                backgroundColor: "transparent",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",  
            }}
        />
    )
}

export default DatePicker;