import { Box, Grid, Typography } from "@mui/joy";
import SecondaryButton from "../UI/custom_elements/secondaryButton"
import Dialog from "../UI/custom_elements/dialog";
import ValidateDocument from "./validateDocument";
import { useContext, useEffect, useState } from "react";
import useGet from "../../hooks/useGet";
import SessionContext from "../../store";
import { Loading2 } from "../UI/custom_elements/loading2";
import DocumentsReview from "./DocumentsReview";

const DashboardTableOperator = ({type, theme, columns}) =>{

    const [openValidate, setValidate] = useState(false);

    const [reviewDocumentsValidation, setReviewDocumentsValidation] = useState(false)

    const [merchantId, setMerchantId] = useState({});

    const [elements, setElements] = useState([]);

    const {error, isLoading, get} = useGet();

    const { session } = useContext(SessionContext);

    const getFullName = (element) => {
        const firstName = element.first_name || '';
        const middleName = element.middle_name || '';
        const lastName = element.father_last_name || '';
        const motherLastName = element.mother_last_name || '';
      
        // Filter out empty or null fields
        const nameParts = [firstName, middleName, lastName, motherLastName].filter(Boolean);
      
        // Join parts with spaces
        const fullName = nameParts.join(' ');
      
        return fullName;
    }

    const fetchData = async () => {
        try {

            if(session.token){
                const data = await get("/admin/merchant/requests?status=" + type, session.token);
                if(data){
                    setElements(data);
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    if(error){
        console.log(error);
    }

    const reviewDocuments = (id) => {
        setMerchantId(id);
        if(type === 'pending'){
            setValidate(true); 
        }
        else{
            setReviewDocumentsValidation(true)
        }
    }

    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[type, session.token])  

    if(isLoading){
        return(
            <Loading2 />
        )
    }    

    return(
        <>
            <Dialog title="" open={openValidate} close={()=> setValidate(false)}>
                <ValidateDocument close={()=> setValidate(false)} merchant={merchantId} fetchData={fetchData} />
            </Dialog>
            <Dialog title="" open={reviewDocumentsValidation} close={()=> setReviewDocumentsValidation(false)}>
                <DocumentsReview close={()=> setReviewDocumentsValidation(false)} merchant={merchantId} title="Resumen de Documentos" />
            </Dialog>
            {
                elements.length === 0 ? (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{height: '100%', width: "calc(100vw - 312px)", mt: 6}}>
                        <Typography sx={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            No hay registros disponibles
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        {
                            elements.map((element, index)=>{
                                return(
                                    <Grid 
                                        key={index}
                                        container 
                                        spacing={2} 
                                        mb={2}
                                        columns={columns} 
                                        sx={{
                                            flexGrow: 1,
                                            borderRadius: "12px",
                                            padding: "8px",
                                            backgroundColor: theme.vars.palette.card
                                        }}>
                                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    {element.createdAt ? element.createdAt.split('T')[0] : "-"}
                                                </Typography>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    {element.user ? getFullName(element.user) :  "-"}
                                                </Typography>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    {element.user && element.user.email ? element.user.email : "-"}
                                                </Typography>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    {element.business_name ? element.business_name : "-"}
                                                </Typography>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                                <SecondaryButton table={true} click={()=> {reviewDocuments(element.id)}} text={type === 'pending' ? "Validar documentos" : "Revisar Validación"} full={true} />
                                            </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Box>
                )
            }
        </>
    )
}

export default DashboardTableOperator;