
import { extendTheme } from '@mui/joy/styles';

const muneyPalette = {
  common: {
    black: "#101218",
    white: "#ffffff",
  },
  primary: {
    "50": "#F3FFFF",
    "100": "#D4FFFF",
    "200": "#BDFFFF",
    "300": "#A0FAFA",
    "400": "#68FAFA",
    "500": "#18E9E9",
    "600": "#00C6C6",
    "700": "#00848C",
    "800": "#004453",
    "900": "#001A1A",
  },
  secondary: {
    "50": "#F3FEFA",
    "100": "#C0FBED",
    "200": "#95F8E0",
    "300": "#6AF4D3",
    "400": "#41EFC5",
    "500": "#18E9B7",
    "600": "#00C99B",
    "700": "#009379",
    "800": "#005C51",
    "900": "#07413E",
  },
  neutral: {
    "50": "#f6f6f6",
    "100": "#d2d4d8",
    "200": "#b5b8c0",
    "300": "#979ca7",
    "400": "#7a808e",
    "500": "#5f6475",
    "600": "#484F5F",
    "700": "#353A48",
    "800": "#222631",
    "900": "#101218",
  },
  successs: {
    "50": "#e8fef0",
    "100": "#bffcd4",
    "200": "#96fab8",
    "300": "#6cf99c",
    "400": "#43f781",
    "500": "#1ae05e",
    "600": "#15c451",
    "700": "#10933d",
    "800": "#0a6228",
    "900": "#053114",
  },
  warning: {
    "50": "#fffbed",
    "100": "#fff5ce",
    "200": "#ffeeae",
    "300": "#ffe88e",
    "400": "#ffe26f",
    "500": "#ffdb4f",
    "600": "#e7c440",
    "700": "#b1993a",
    "800": "#665820",
    "900": "#332c10",
  },
  danger: {
    "50": "#fee7e9",
    "100": "#fbbcc2",
    "200": "#f9909b",
    "300": "#f76574",
    "400": "#f4394c",
    "500": "#f20e25",
    "600": "#c20b1e",
    "700": "#910816",
    "800": "#61060f",
    "900": "#300307",
  },
};


const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        ...muneyPalette,
        background: {
          body: "#D4FFFF",
          sideBar: "#F3FFFF",
          configuration: "#031D27",
        },
        text: {
          main: "#031D27",
          secondary: "#082B39",
          button: "#085262"
        },  
        icon: "#031D27",
        card: "#F3FFFF"
      }
    },
    dark: {
      palette: {
        mode: "dark",
        ...muneyPalette,
        background: {
          body: "#031D27",
          sideBar: "#082B39",
          configuration: "#D4FFFF",
        },
        text: {
          main: "#F6F6F6",
          secondary: "#082B39",
          button: "#085262"
        },  
        icon: "#F6F6F6",
        card: "#082B39"
      }
    }
  }
})

export default theme;