import { Box } from "@mui/joy";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const MapClient = ({getLocation, center, coordinates, height = "300px"}) => {

    //const theme = useTheme();

    const containerStyle = {
        width: '100%',
        height: height,
        borderRadius: "16px"
    };
      
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

    return (
        <>
            <Box>
                {
                    isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={15}
                        >
                            {
                                coordinates.map((coordinate, index) => {
                                    return(
                                        <Marker 
                                            key={index} 
                                            position={{ lat: coordinate.lat, lng: coordinate.lng }}
                                            draggable={true}
                                            onDragEnd={(e) => {
                                                getLocation(e.latLng.lat(), e.latLng.lng()); 
                                            }}
                                        />
                                    )
                                })
                            }
                        </GoogleMap>
                    ) : <></>
                }
            </Box>
        </>
    )
} 

export default MapClient;