import { Box, Grid, Typography, useTheme } from "@mui/joy";
import DocumentIcon from "../icons/documents";
import SessionContext from "../../store";
import useGet from "../../hooks/useGet";
import { useContext, useEffect, useState } from "react";
import { Loading2 } from "../UI/custom_elements/loading2";

const ClientDetails = ({ close, client }) => {

    const theme = useTheme();

    const { session } = useContext(SessionContext);

    const [clientDetail, setClientDetail] = useState(undefined);
    const [clientHistory, setClientHistory] = useState([]);


    const { error, isLoading, get } = useGet();

    if (error) {
        console.log(error);
    }

    const fetchData = async () => {
        try {

            const result = await get("/admin/clients/" + client._id, session.token);
            const history = await get("/admin/clients/" + client._id + "/history", session.token);

            if (result) {
                setClientDetail(result);
            }
            if (history) {
                setClientHistory(history);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDownload = (new_url) => {
        console.log(new_url)
        window.open(new_url, '_blank');
    };

    const formatAddress = (address) => {
        const orderedFields = [
            'street',
            'street_number',
            'locality',
            'zip',
            'municipality',
            'city',
            'country'
        ];

        const formattedValues = orderedFields
            .map(field => address && address[field])
            .filter(value => value !== undefined);

        return formattedValues.join(' ');
    };

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Box width={720} height={500}>
                {
                    isLoading ? (
                        <Box sx={{ overflowY: "auto", maxHeight: "500px" }}>
                            <Loading2 />
                        </Box>
                    ) : (
                        <Box sx={{ overflowY: "auto", maxHeight: "500px" }}>
                            {
                                clientDetail && (
                                    <Box>
                                        <Box>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "20px",
                                                fontStyle: "normal",
                                                fontWeight: 700,
                                                lineHeight: "120%",
                                                color: theme.vars.palette.primary["500"],
                                            }}>
                                                Datos de cliente
                                            </Typography>
                                        </Box>
                                        <Box mt={3} sx={{
                                            borderRadius: "24px",
                                            padding: "24px",
                                            backgroundColor: theme.vars.palette.card
                                        }}>
                                            <Box mb={2}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    {clientDetail.first_name + " " + (clientDetail.middle_name ? clientDetail.middle_name + " " : "") + clientDetail.father_last_name + " " + clientDetail.mother_last_name}
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }} mb={2}>
                                                <Grid md={1}>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "12px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "130%",
                                                        color: theme.vars.palette.text.main,
                                                    }}>
                                                        Nivel
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: 700,
                                                        lineHeight: "150%",
                                                        color: theme.vars.palette.text.main,
                                                    }}>
                                                        {clientDetail.level}
                                                    </Typography>
                                                </Grid>
                                                <Grid md={1}>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "12px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "130%",
                                                        color: theme.vars.palette.text.main,
                                                    }}>
                                                        Teléfono
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: 700,
                                                        lineHeight: "150%",
                                                        color: theme.vars.palette.text.main,
                                                    }}>
                                                        {clientDetail.country_code} {clientDetail.mobile}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }}>
                                                {
                                                    clientDetail.address && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Dirección
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                lineHeight: "150%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                {formatAddress(clientDetail.address)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    clientDetail.email && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Correo
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                lineHeight: "150%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                {clientDetail.email}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            <Grid container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }} mb={2}>
                                                {
                                                    client.score && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Score
                                                            </Typography>
                                                            <Box display="flex">
                                                                <Box>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 700,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        {client.score}
                                                                    </Typography>
                                                                </Box>
                                                                <Box ml={1}>
                                                                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.61914 7.63498L7.88913 4.36499" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M4.82399 5.18495C5.16365 5.18495 5.43898 4.90962 5.43898 4.56996C5.43898 4.23031 5.16365 3.95496 4.82399 3.95496C4.48433 3.95496 4.20898 4.23031 4.20898 4.56996C4.20898 4.90962 4.48433 5.18495 4.82399 5.18495Z" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M8.09351 8.04494C8.43316 8.04494 8.70851 7.76959 8.70851 7.42993C8.70851 7.09028 8.43316 6.81494 8.09351 6.81494C7.75385 6.81494 7.47852 7.09028 7.47852 7.42993C7.47852 7.76959 7.75385 8.04494 8.09351 8.04494Z" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M6.33398 11C9.09541 11 11.334 8.76142 11.334 6C11.334 3.23858 9.09541 1 6.33398 1C3.57256 1 1.33398 3.23858 1.33398 6C1.33398 8.76142 3.57256 11 6.33398 11Z" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    clientDetail.status && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Estatus
                                                            </Typography>
                                                            <Box>
                                                                {
                                                                    clientDetail.status === "active" && (
                                                                        <Box display="flex">
                                                                            <Box>
                                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="6" cy="6" r="6" fill="#1AF565" />
                                                                                </svg>
                                                                            </Box>
                                                                            <Box ml={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Activo
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                                {
                                                                    clientDetail.status === "suspended" && (
                                                                        <Box display="flex">
                                                                            <Box>
                                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="6.66699" cy="6" r="6" fill="#B1993A" />
                                                                                </svg>
                                                                            </Box>
                                                                            <Box ml={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Suspendido
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                                {
                                                                    clientDetail.status === "blocked" && (
                                                                        <Box display="flex">
                                                                            <Box>
                                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="6.33301" cy="6" r="6" fill="#F20E25" />
                                                                                </svg>
                                                                            </Box>
                                                                            <Box ml={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Bloqueado
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                                {
                                                                    clientDetail.status !== "blocked" && clientDetail.status !== "suspended" && clientDetail.status !== "active" && (
                                                                        <Box display="flex">
                                                                            <Box>
                                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="6.33301" cy="6" r="6" fill="#F20E25" />
                                                                                </svg>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Estatus desconocido
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            <Grid container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }} mt={3}>
                                                {
                                                    (clientDetail.birthday) && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Fecha de nacimiento
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                lineHeight: "150%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                {convertDateFormat(clientDetail.birthday)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    (clientDetail.business_name && clientDetail.level > 2) ? (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Nombre del negocio
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                lineHeight: "150%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                {clientDetail.business_name ? clientDetail.business_name : ""}
                                                            </Typography>
                                                        </Grid>
                                                    ) : (clientDetail.merchant_request && clientDetail.merchant_request.business_name) && clientDetail.level > 2 && (
                                                        <Grid md={1}>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                Nombre del negocio
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: 700,
                                                                lineHeight: "150%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                {clientDetail.merchant_request && clientDetail.merchant_request.business_name ? clientDetail.merchant_request.business_name : ""}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            {
                                                (clientDetail.birthday) && (
                                                    <Grid container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }} mt={3}>
                                                    </Grid>
                                                )
                                            }
                                        </Box>
                                        {
                                            clientDetail.kyc && (
                                                <Box>
                                                    <Box mt={3}>
                                                        <Typography sx={{
                                                            fontFamily: "Montserrat",
                                                            fontSize: "20px",
                                                            fontStyle: "normal",
                                                            fontWeight: 700,
                                                            lineHeight: "120%",
                                                            color: theme.vars.palette.primary["500"],
                                                        }}>
                                                            Nivel 2
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={3} sx={{
                                                        borderRadius: "24px",
                                                        padding: "24px",
                                                        backgroundColor: theme.vars.palette.card
                                                    }}>
                                                        <Box>
                                                            <Typography sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: "12px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "130%",
                                                                color: theme.vars.palette.text.main,
                                                            }}>
                                                                KYC
                                                            </Typography>
                                                        </Box>
                                                        <Grid mt={1} container spacing={2} columns={2} display="flex" sx={{ flexGrow: 1 }}>
                                                            {
                                                                clientDetail.kyc.status && (
                                                                    <Grid md={1}>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "12px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 400,
                                                                            lineHeight: "130%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            Status
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "16px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 700,
                                                                            lineHeight: "150%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            {clientDetail.kyc.status}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            }
                                                            {
                                                                clientDetail.kyc.output && clientDetail.kyc.output.aml_information && (
                                                                    <Grid md={1}>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "12px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 400,
                                                                            lineHeight: "130%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            Aml information
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "16px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 700,
                                                                            lineHeight: "150%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            {clientDetail.kyc.output.aml_information.status}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            }
                                                            {
                                                                clientDetail.kyc.output && clientDetail.kyc.output.biometric_information && (
                                                                    <Grid md={1}>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "12px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 400,
                                                                            lineHeight: "130%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            Biometric information
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "16px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 700,
                                                                            lineHeight: "150%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            {clientDetail.kyc.output.biometric_information.status}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            }
                                                            {
                                                                clientDetail.kyc.output && clientDetail.kyc.output.document_information && (
                                                                    <Grid md={1}>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "12px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 400,
                                                                            lineHeight: "130%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            Document information
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "16px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 700,
                                                                            lineHeight: "150%",
                                                                            color: theme.vars.palette.text.main,
                                                                        }}>
                                                                            {clientDetail.kyc.output.document_information.status}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            clientDetail.merchant_request && clientDetail.level > 2 && (
                                                <Box>
                                                    <Box mt={3}>
                                                        <Typography sx={{
                                                            fontFamily: "Montserrat",
                                                            fontSize: "20px",
                                                            fontStyle: "normal",
                                                            fontWeight: 700,
                                                            lineHeight: "120%",
                                                            color: theme.vars.palette.primary["500"],
                                                        }}>
                                                            Documentos de merchant
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={3} sx={{
                                                        borderRadius: "24px",
                                                        padding: "24px",
                                                        backgroundColor: theme.vars.palette.card
                                                    }}>
                                                        <Grid mt={1} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Identificación
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.identification)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Acta constitutiva
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.constitutive_act)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid mt={1} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    RIF
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.rif)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Declaración de impuestos
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.tax_return)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid mt={1} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Comprobante de domicilio
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.proof_address)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                            <Grid md={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "130%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Foto delantera
                                                                </Typography>
                                                                <Box onClick={() => handleDownload(clientDetail.merchant_request.front_photo)} mt={1} sx={{
                                                                    padding: "4px 16px",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    backgroundColor: theme.vars.palette.background.body,
                                                                    borderRadius: "12px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                        color: theme.vars.palette.text.main,
                                                                    }}>
                                                                        Documento.pdf
                                                                    </Typography>
                                                                    <DocumentIcon />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            clientHistory && clientHistory.length > 0 && (
                                                <Box>
                                                    <Box mt={3}>
                                                        <Typography sx={{
                                                            fontFamily: "Montserrat",
                                                            fontSize: "20px",
                                                            fontStyle: "normal",
                                                            fontWeight: 700,
                                                            lineHeight: "120%",
                                                            color: theme.vars.palette.primary["500"],
                                                        }}>
                                                            Historial de actualizaciones
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        clientHistory.map((history, index) => {                                                      
                                                            history.fields = history.fields || {};
                                                            history.fields.profile = history.fields.profile || {};  
                                                            return (
                                                                <Box key={index} mt={3} sx={{
                                                                    borderRadius: "24px",
                                                                    padding: "24px",
                                                                    backgroundColor: theme.vars.palette.card
                                                                }}>
                                                                    <Grid mt={1} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                                                                        <Grid md={1}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Usuario que realizó la edición
                                                                            </Typography>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 700,
                                                                                lineHeight: "150%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                {history.admin.first_name || ''} {history.admin.last_name || ''}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid md={1}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Fecha de edición
                                                                            </Typography>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 700,
                                                                                lineHeight: "150%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                {history.createdAt ? convertDateFormat(history.createdAt) : ''}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid md={2}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Email
                                                                            </Typography>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "16px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 700,
                                                                                lineHeight: "150%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                {history.admin.email || ''}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid md={1}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Foto
                                                                            </Typography>
                                                                            <Box onClick={() => handleDownload(history.photo)} mt={1} sx={{
                                                                                padding: "4px 16px",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                backgroundColor: theme.vars.palette.background.body,
                                                                                borderRadius: "12px",
                                                                                cursor: "pointer"
                                                                            }}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Imagen
                                                                                </Typography>
                                                                                <DocumentIcon />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid md={1}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Identificación
                                                                            </Typography>
                                                                            <Box onClick={() => handleDownload(history.identification)} mt={1} sx={{
                                                                                padding: "4px 16px",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                backgroundColor: theme.vars.palette.background.body,
                                                                                borderRadius: "12px",
                                                                                cursor: "pointer"
                                                                            }}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Documento.pdf
                                                                                </Typography>
                                                                                <DocumentIcon />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid md={1}>
                                                                            <Typography sx={{
                                                                                fontFamily: "Montserrat",
                                                                                fontSize: "12px",
                                                                                fontStyle: "normal",
                                                                                fontWeight: 400,
                                                                                lineHeight: "130%",
                                                                                color: theme.vars.palette.text.main,
                                                                            }}>
                                                                                Carta
                                                                            </Typography>
                                                                            <Box onClick={() => handleDownload(history.letter)} mt={1} sx={{
                                                                                padding: "4px 16px",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                backgroundColor: theme.vars.palette.background.body,
                                                                                borderRadius: "12px",
                                                                                cursor: "pointer"
                                                                            }}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Documento.pdf
                                                                                </Typography>
                                                                                <DocumentIcon />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box mt={3}>
                                                                        <Typography sx={{
                                                                            fontFamily: "Montserrat",
                                                                            fontSize: "12px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: 700,
                                                                            lineHeight: "120%",
                                                                            color: theme.vars.palette.primary["500"],
                                                                        }}>
                                                                            Campos editados
                                                                        </Typography>
                                                                    </Box>
                                                                    <Grid mt={1} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
                                                                        {history.fields.profile && history.fields.profile.country_code && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Lada:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.country_code || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.country_short && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Country Short:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.country_short || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.first_name && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Nombre:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.first_name || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.middle_name && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Segundo nombre:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.middle_name || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.father_last_name && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Apellido paterno:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.father_last_name || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.mother_last_name && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Apellido materno:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.mother_last_name || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.mobile && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Télefono:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.mobile || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.birthday && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Fecha de nacimiento:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.birthday ? convertDateFormat(history.fields.profile.birthday) : ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.status && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Status:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.status || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {history.fields.profile && history.fields.profile.email && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Email:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.profile.email || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        {history.fields.merchant && history.fields.merchant.business_name && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Nombre del negocio:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.merchant.business_name || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        {history.fields.merchant && history.fields.merchant.latitude && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Latitud:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.merchant.latitude || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        {history.fields.merchant && history.fields.merchant.longitude && (
                                                                            <Grid md={1}>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "12px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "130%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    Longitud:
                                                                                </Typography>
                                                                                <Typography sx={{
                                                                                    fontFamily: "Montserrat",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 700,
                                                                                    lineHeight: "150%",
                                                                                    color: theme.vars.palette.text.main,
                                                                                }}>
                                                                                    {history.fields.merchant.longitude || ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            )
                                        }
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Box>
        </>
    )
}

export default ClientDetails;