import Login from "./pages/login";
import Clients from "./pages/clients";
import Users from "./pages/users";
import Transactions from "./pages/transactions";
import DashboardOperator from "./pages/dashboardOperator"

import Home from "./components/UI/layout/home";

import theme from "./components/theme"

import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { CssVarsProvider } from "@mui/joy";

import { UIContextProvider } from "./store/ui";
import { SessionContextProvider } from "./store";
import RecoverPassword from "./pages/recoverPassword";

const App = () => {

  return (
      <CssVarsProvider theme={theme} defaultMode="system">
        <SessionContextProvider>
          <UIContextProvider>
            <BrowserRouter>
              <Routes>            
                <Route path="/" element={<Login />} />
                <Route path="/recover_password" element={<RecoverPassword />} />
                <Route path="/dashboard" element={<Home><DashboardOperator /></Home>} />
                {/* <Route path="/dashboard_operator" element={<Home><DashboardOperator /></Home>} /> */}
                <Route path="/clients" element={<Home><Clients /></Home>} />
                <Route path="/users" element={<Home><Users /></Home>} />
                <Route path="/transactions" element={<Home><Transactions /></Home>} />
                {/* <Route path="/financial_report" element={<Home><FinancialReport /></Home>} /> */}
                {/* <Route path="/maps" element={<Home><Maps /></Home>} /> */}
                <Route path="*" element={<Home><DashboardOperator /></Home>} />
              </Routes>
            </BrowserRouter>   
          </UIContextProvider>
        </SessionContextProvider>
      </CssVarsProvider>
  );
}

export default App;
