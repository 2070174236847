const TransactionsIcon = ({theme}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8666 5.77999V9.21334C12.8666 11.2667 11.6933 12.1466 9.93332 12.1466H4.07331C3.77331 12.1466 3.48665 12.12 3.21998 12.06C3.05331 12.0334 2.89332 11.9867 2.74665 11.9333C1.74665 11.56 1.13998 10.6933 1.13998 9.21334V5.77999C1.13998 3.72666 2.31331 2.84668 4.07331 2.84668H9.93332C11.4267 2.84668 12.5 3.48001 12.7867 4.92668C12.8333 5.19334 12.8666 5.46666 12.8666 5.77999Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.8674 7.78007V11.2134C14.8674 13.2668 13.694 14.1467 11.934 14.1467H6.07404C5.5807 14.1467 5.13404 14.0801 4.74738 13.9334C3.95404 13.6401 3.41404 13.0334 3.2207 12.0601C3.48737 12.1201 3.77404 12.1467 4.07404 12.1467H9.93404C11.694 12.1467 12.8674 11.2668 12.8674 9.21342V5.78007C12.8674 5.46674 12.8407 5.18676 12.7874 4.92676C14.054 5.19342 14.8674 6.08674 14.8674 7.78007Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.99896 9.25993C7.97098 9.25993 8.75897 8.47195 8.75897 7.49992C8.75897 6.5279 7.97098 5.73991 6.99896 5.73991C6.02694 5.73991 5.23895 6.5279 5.23895 7.49992C5.23895 8.47195 6.02694 9.25993 6.99896 9.25993Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.18665 6.03333V8.96667" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.8145 6.03352V8.96687" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default TransactionsIcon;