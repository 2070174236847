const Login3 = () => {
    return (
        <svg width="279" height="190" viewBox="0 0 279 190" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_70_2792)">
            <path d="M90.2396 92.032L94.0726 89.5628C123.95 71.4769 130.208 70.4303 130.208 70.4303C136.449 65.59 121.674 70.0869 109.11 44.2336C104.556 34.8472 100.199 29.1729 96.5133 25.7879C90.0431 19.852 81.1977 23.7275 81.1977 23.7275C65.1285 27.0307 49.0594 31.4132 36.4138 41.6008C34.2843 43.2851 32.2368 45.2474 31.385 47.7984C30.5988 50.1859 31.1885 53.2275 33.2688 54.5847C33.2851 54.6011 33.3015 54.6011 33.3179 54.6174C33.2032 54.6992 33.0886 54.7809 32.9739 54.8627C21.9172 63.0717 10.7785 71.4605 2.14609 82.0897C0.950323 83.5777 0.393391 85.2784 0.0494038 87.1426C-0.163541 88.1237 0.393391 90.4458 0.8029 91.3779C1.17965 92.2119 1.8021 92.866 2.65388 93.193C3.84965 93.6673 5.19283 93.3729 6.43774 92.9804C14.0218 90.7401 20.7706 86.325 27.3718 81.9588C33.4981 77.9198 39.608 73.8971 45.7506 69.8743C44.9316 72.8832 44.1289 75.9575 44.4238 79.0645C44.4565 79.5387 44.5548 80.0129 44.6695 80.4871C44.7678 80.9613 44.8824 81.4519 45.0626 81.9261L45.079 81.9425C45.8489 83.9702 47.2084 85.7526 49.1741 86.6357C50.3371 87.1753 51.6475 87.3552 52.7941 87.9602C55.3659 89.3992 56.2668 92.6043 57.9212 95.0082C61.3611 99.9957 68.29 101.5 74.1869 100.225C80.1166 98.9982 85.26 95.466 90.2396 92.032Z" fill="#A0FAFA"/>
            <path d="M121.51 24.3816C114.892 17.4318 101.427 19.2796 101.427 19.2796C85.3581 22.5828 49.0756 31.4296 36.43 41.6172C34.3006 43.3015 32.253 45.2638 31.4012 47.8148C30.615 50.2023 31.2047 53.2438 33.285 54.6011C34.448 53.3256 35.8567 52.1973 37.2982 51.118C52.9578 39.4913 72.1392 33.5717 91.2387 28.8131C91.2387 28.8131 120.953 24.4307 121.51 24.3816Z" fill="#18E9E9"/>
            <path d="M84.539 22.174C83.9657 22.0105 83.6544 21.9451 83.6544 21.9451L83.1958 21.9124C83.3596 21.9941 83.9002 22.0268 84.064 22.1086C84.2114 22.125 84.3916 22.1413 84.539 22.174Z" fill="#18E9E9"/>
            <path d="M91.6318 25.1011C92.1396 24.9703 111.829 2.02768 127.226 30.1867C142.624 58.3458 135.81 65.8516 122.525 71.8693" fill="#A0FAFA"/>
            <path d="M677 104.493L674.347 115.334L663.47 159.895C661.767 161.17 547.203 245.631 399.435 125.849C330.9 70.2831 262.856 56.5961 209.358 57.9861C208.326 58.0024 207.31 58.0351 206.278 58.0842C205.607 58.1005 204.935 58.1332 204.28 58.1659C202.478 58.2313 200.676 58.3295 198.891 58.4439C194.763 58.7056 190.75 59.049 186.835 59.4741C185 59.6704 183.182 59.8829 181.396 60.1119C180.496 60.2263 179.595 60.3408 178.694 60.4716C176.368 60.7823 174.091 61.1257 171.863 61.4855C171.028 61.6163 170.192 61.7635 169.357 61.8943C168.292 62.0742 167.244 62.2541 166.212 62.4503C166.13 62.4666 166.032 62.483 165.95 62.4993C163.313 62.8428 141.478 65.8353 117.104 74.6329C112.746 76.2028 108.651 77.3311 104.867 78.0833H104.851L110.355 55.5659L94.5806 20.7186C94.5806 20.7186 264.068 -50.2513 412.966 70.4303C546.154 178.39 652.348 120.453 673.249 107.044C674.478 106.259 675.428 105.605 676.05 105.163C676.46 104.885 676.738 104.689 676.886 104.574C676.919 104.542 676.951 104.525 676.968 104.509C676.984 104.493 677 104.493 677 104.493Z" fill="#A0FAFA"/>
            <path d="M677 104.493C677 104.493 676.984 104.493 676.968 104.509C676.951 104.525 676.918 104.542 676.886 104.574C676.738 104.689 676.46 104.885 676.05 105.163C675.428 105.605 674.478 106.259 673.249 107.044C652.348 120.453 544.582 180.303 412.965 70.4303C247.917 -67.3397 37.1182 41.2084 37.1182 41.2084L62.95 37.4309C84.5229 30.2195 161.101 11.4141 239.334 19.5903C300.482 25.9842 359.877 51.1507 412.965 96.1038C537.358 201.43 641.832 151.408 669.547 134.974L674.347 115.334L677 104.493Z" fill="#18E9E9"/>
            </g>
            <defs>
            <clipPath id="clip0_70_2792">
            <rect width="677" height="190" fill="white" transform="matrix(1 0 0 -1 0 190)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default Login3;