import { Box, Grid, Typography } from "@mui/joy";

const HeadTable = ({heads, theme}) => {
    return (
        <Box width="100%">
            <Grid container spacing={2} columns={heads.length} sx={{flexGrow: 1}}>
                {
                    heads.map((head, index)=>{
                        return(
                            <Grid display="flex" justifyContent="center" key={index} md={1}>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    {head}
                                </Typography>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default HeadTable;