import { Box, Typography, useTheme } from "@mui/joy";
import MuneyLogin from "../../components/icons/muneyLogin";
import CustomInput from "../../components/UI/custom_elements/customInput";
import PrimaryButton from "../../components/UI/custom_elements/primaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import Login1 from "../../components/icons/login/login_1";
import Login2 from "../../components/icons/login/login_2";
import Login3 from "../../components/icons/login/login_3";
import Login4 from "../../components/icons/login/login_4";
import MuneyIcon from "../../components/icons/muney";
import { useEffect, useState } from "react";
import usePost from "../../hooks/usePost";

const RecoverPassword = () =>{

    const theme = useTheme();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const [recover, setRecover] = useState({
        token: "",
        password: "",
        password_confirm: ""
    });

    const { post } = usePost();    

    useEffect(()=>{
        setRecover(prevRecover => ({
            ...prevRecover,
            token: token
        }));
    },[token])

    const recoverPassword = async () =>{
        try{
            await post("/admin/password/recover", recover)
            navigate("/login")
        }
        catch(error){

        }
    }

    return(
        <>
            <Box display={{xs: "none", md: "block"}} m={0} p={0} sx={{width: "100vw", height: "100dvh", backgroundColor: theme.vars.palette.background.body}}>
                <Box sx={{position: "fixed"}}>
                    <Login1 />
                </Box>
                <Box sx={{position: "fixed", bottom: "0px"}}>
                    <Login2 />
                </Box>
                <Box sx={{position: "fixed", bottom: "164px", right: "0px"}}>
                    <Login3 />
                </Box>
                <Box sx={{position: "fixed", bottom: "0px", right: "0px"}}>
                    <Login4 />
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                    <Box>
                        <Box  display="flex" justifyContent="center" mt={{md: "100px", xl:"200px"}}>
                            <MuneyLogin />
                        </Box>
                        <Box width={566} mt={5}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "120%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    Recuperación de contraseña
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                <CustomInput 
                                    placeholder="Contraseña" 
                                    width="100%" 
                                    password={true} 
                                    setValue={(value) =>   
                                        setRecover(prevUser => ({
                                            ...prevUser,
                                            password: value 
                                        })
                                    )} 
                                    value={recover.password}
                                />
                            </Box>
                            <Box mt={2}>
                                <CustomInput 
                                    placeholder="Confirmar contraseña" 
                                    width="100%" 
                                    password={true} 
                                    setValue={(value) =>   
                                        setRecover(prevUser => ({
                                            ...prevUser,
                                            password_confirm: value 
                                        })
                                    )} 
                                    value={recover.password_confirm}
                                />
                            </Box>
                            <Box mt={4} width="100%">
                                <PrimaryButton click={recoverPassword} text="Restaurar contraseña" full={true} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={{xs: "block", md: "none"}} m={0} p={0} sx={{width: "100vw", height: "100dvh", backgroundColor: theme.vars.palette.background.body}}>
                <Box display="flex" justifyContent="center" width="100%">
                    <Box>
                        <Box  display="flex" justifyContent="center" mt="50px">
                            <MuneyIcon />
                        </Box>
                        <Box mt={5} >
                            <Box display="flex" justifyContent="center">
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "120%",
                                    textAlign: "center",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    No disponible en Dispositivos móviles
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default RecoverPassword;