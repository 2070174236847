import { Box, Grid, Option, Select, Typography, useTheme } from "@mui/joy";
import Snackbar from '@mui/joy/Snackbar';
import CustomInput from "../UI/custom_elements/customInput"
import PrimaryButton from "../UI/custom_elements/primaryButton";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import { useContext, useEffect, useState } from "react";
import usePost from "../../hooks/usePost";
import usePut from "../../hooks/usePut";
import SessionContext from "../../store";

const NewUser = ({edit = false, editUser = undefined, close, fetchData}) => {

    const theme = useTheme();
    const [user, setUser] = useState(editUser ? editUser : {
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        mobile: "",
        password: "",
        status: "active"
    });

    const [idUser, setIdUser] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState(false);

    const { session } = useContext(SessionContext);

    const { post } = usePost();
    const { put } = usePut();


    const save = async () => {
        if(!edit){
        
            const response = await post("/admin/users", user, session.token);
            if(response){
                fetchData();
                close();
            }
            else{
                setErrorMessage(true);
            }
        }
        else{
            try{
                const response = await put(process.env.REACT_APP_URL_API + "/admin/users/" + idUser, user, session.token);
                if(response){
                    fetchData();
                    close();
                }
                else{
                    setErrorMessage(true);
                }
            }
            catch(error){
                console.log(error);
                setErrorMessage(true);
            }
        }
    }

    useEffect(()=>{
        if(editUser !== undefined){
            setUser({
                first_name: editUser.first_name,
                last_name: editUser.last_name,
                email: editUser.email,
                country_code: editUser.country_code,
                mobile: editUser.mobile,
                password: editUser.password,              
            });
            setIdUser(editUser._id)
        }
    },[editUser]);

    const isAnyFieldEmpty = () => {
        for (const key in user) {
            if (user.hasOwnProperty(key) && user[key] === "") {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Box width={500}>
                <Snackbar
                    open={errorMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    variant="solid"
                    color="danger"
                    autoHideDuration={4000}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') {
                          return;
                        }
                        setErrorMessage(false);
                    }}
                >
                    Ocurrió un problema, por favor valide que su información sea valida.    
                </Snackbar>
                <Snackbar
                    open={infoMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    variant="solid"
                    color="neutral"
                    autoHideDuration={4000}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setInfoMessage(false);
                    }}
                >
                    Complete la información antes de continuar   
                </Snackbar> 
                <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <Typography mb={0.5} sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {"Nombre (s)"}
                        </Typography>
                        <CustomInput 
                            placeholder="Nombre (s)" 
                            width="100%"
                            setValue={(value) =>   
                                setUser(prevUser => ({
                                    ...prevUser,
                                    first_name: value 
                                })
                            )} 
                            value={user.first_name} 
                        />
                    </Grid>
                    <Grid md={1}>
                        <Typography mb={0.5} sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {"Apellido (s)"}
                        </Typography>
                        <CustomInput 
                            setValue={(value) =>   
                                setUser(prevUser => ({
                                    ...prevUser,
                                    last_name: value 
                                }))} 
                            value={user.last_name}
                            placeholder="Apellido (s)" 
                            width="100%" />
                    </Grid>
                </Grid>
                <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <Typography mb={0.5} sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            Mail
                        </Typography>
                        <CustomInput 
                            setValue={(value) =>   
                                setUser(prevUser => ({
                                    ...prevUser,
                                    email: value 
                                })
                            )} 
                            value={user.email}
                            placeholder="correo@ejemplo.com" 
                            width="100%" 
                        />
                    </Grid>
                    <Grid md={1}>
                        <Typography mb={0.5} sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {"Código de pais"}
                        </Typography>
                        <Select value={user.country_code} width="200px" placeholder="Elige un Tipo" onChange={(e, newValue)=> {
                            console.log(newValue)
                            setUser(prevUser => ({
                                ...prevUser,
                                country_code: newValue
                            }))
                        }} 
                            sx={{
                                display: "flex",
                                padding: "8px 16px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                color: theme.vars.palette.text.main,
                                gap: "8px",
                                borderRadius: "12px",
                                border: "2px solid #18E9E9",
                                backgroundColor: theme.vars.palette.background.body,  
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "120%",                
                            }} 
                        
                        >
                            <Option value={"+52"}>México</Option>
                        </Select>
                    </Grid>
                </Grid>
                <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <Typography mb={0.5} sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {"Teléfono"}
                        </Typography>
                        <CustomInput
                            placeholder="5510101010" 
                            width="100%"
                            setValue={(value) =>   
                                setUser(prevUser => ({
                                    ...prevUser,
                                    mobile: value 
                                })
                            )}  
                            value={user.mobile}
                        />
                    </Grid>
                    {
                        !edit && (
                            <Grid md={1}>
                                <Typography mb={0.5} sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "150%",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    Contraseña
                                </Typography>
                                <CustomInput 
                                    setValue={(value) =>   
                                        setUser(prevUser => ({
                                            ...prevUser,
                                            password: value 
                                        })
                                    )} 
                                    value={user.password}
                                    placeholder="Contraseña" 
                                    width="100%" 
                                />
                            </Grid>

                        )
                    }
                </Grid>
                <Grid mt={2} container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <SecondaryButton click={()=> close()} text="Cancelar" full={true} />
                    </Grid>
                    <Grid md={1}>
                        <PrimaryButton click={()=> isAnyFieldEmpty() === true ? setInfoMessage(true) : save()} text="Guardar" full={true} desactived={isAnyFieldEmpty()} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
} 

export default NewUser;