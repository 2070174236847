import { Box, Grid, Typography, useTheme } from "@mui/joy";
import MuneyLogin from "../../components/icons/muneyLogin";
import CustomInput from "../../components/UI/custom_elements/customInput";
import PrimaryButton from "../../components/UI/custom_elements/primaryButton";
import { useNavigate } from "react-router-dom";
import Login1 from "../../components/icons/login/login_1";
import Login2 from "../../components/icons/login/login_2";
import Login3 from "../../components/icons/login/login_3";
import Login4 from "../../components/icons/login/login_4";
import MuneyIcon from "../../components/icons/muney";
import { useContext, useState } from "react";
import SecondaryButton from "../../components/UI/custom_elements/secondaryButton";
import SessionContext from "../../store";
import usePost from "../../hooks/usePost";

const Login = () =>{

    const theme = useTheme();
    const navigate = useNavigate();

    const { setActualSession} = useContext(SessionContext);

    const [recoverPassword, setRecoverPassword] = useState(false);

    const [session, setSession] = useState({
        email: "",
        password: ""
    });

    const [errorMessage, setErrorMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    const { post: postRecovery } = usePost();

    const showError = (message) => {
        setErrorMessage(message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      };
    
    const login = async () =>{
        try{
            setLoading(true);
            const result = await setActualSession(session);
            if(result && result.token){
                if(result.type === "admin")
                    navigate("/dashboard")
                else    
                    navigate("/dashboard_operator") 
            }
            else{
                setLoading(false)
                showError("Error en el inicio de sesión. Por favor, inténtalo de nuevo.");
            }
        }
        catch(error){
            setLoading(false)
            console.log("Error 2: ",error)
            showError("Error en el inicio de sesión. Por favor, inténtalo de nuevo.");
        }
    }

    const recoveryPasswordEmail = async () => {
        try{
            await postRecovery("/admin/password/send-email", {email: session.email})
        }
        catch(error){
            console.log(error)
        }
    }

    return(
        <>
            <Box display={{xs: "none", md: "block"}} m={0} p={0} sx={{width: "100vw", height: "100dvh", backgroundColor: theme.vars.palette.background.body}}>
                <Box sx={{position: "fixed"}}>
                    <Login1 />
                </Box>
                <Box sx={{position: "fixed", bottom: "0px"}}>
                    <Login2 />
                </Box>
                <Box sx={{position: "fixed", bottom: "164px", right: "0px"}}>
                    <Login3 />
                </Box>
                <Box sx={{position: "fixed", bottom: "0px", right: "0px"}}>
                    <Login4 />
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                    <Box>
                        <Box  display="flex" justifyContent="center" mt={{md: "100px", xl:"200px"}}>
                            <MuneyLogin />
                        </Box>
                        {
                            !recoverPassword ? (
                                <Box width={566} mt={5}>
                                    <Box>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "20px",
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                            color: theme.vars.palette.text.main,
                                        }}>
                                            Inicio de sesión
                                        </Typography>
                                    </Box>
                                    <Box mt={4}>
                                        <CustomInput 
                                            placeholder="Correo electrónico" 
                                            width="100%" 
                                            setValue={(value) =>   
                                                setSession(prevUser => ({
                                                    ...prevUser,
                                                    email: value 
                                                })
                                            )} 
                                            value={session.email}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <CustomInput 
                                            placeholder="Contraseña" 
                                            width="100%" 
                                            password={true} 
                                            setValue={(value) =>   
                                                setSession(prevUser => ({
                                                    ...prevUser,
                                                    password: value 
                                                })
                                            )} 
                                            value={session.password}
                                        />
                                    </Box>
                                    <Box mt={4} width="100%">
                                        <PrimaryButton click={login}  text="Iniciar sesión" full={true} loading={loading} />
                                    </Box>
                                    <Box mt={2}>
                                        <Box sx={{cursor: "pointer"}} onClick={()=> setRecoverPassword(true)}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                textAlign: "center",
                                                color: theme.vars.palette.primary["500"],
                                            }}>
                                                ¿Olvidaste tu contraseña?
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {errorMessage && (
                                        <Box mt={2}>
                                            <Typography
                                                sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                                textAlign: "center",
                                                color: 'red',
                                                }}
                                            >
                                                {errorMessage}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <Box width={566} mt={5}>
                                    <Box>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "20px",
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                            color: theme.vars.palette.text.main,
                                        }}>
                                            ¿Has olvidado tu contraseña?
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            color: theme.vars.palette.text.main,
                                        }}>
                                            Te enviaremos las instrucciones a tu e-mail para restablecer tu contraseña.
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <CustomInput 
                                            type="email" 
                                            placeholder="Correo electrónico" 
                                            width="100%" 
                                            setValue={(value) =>   
                                                setSession(prevUser => ({
                                                    ...prevUser,
                                                    email: value 
                                                })
                                            )} 
                                            value={session.email}
                                        />
                                    </Box>
                                    <Grid mt={2} container columns={2} spacing={2} sx={{flexGrow: 1}}>
                                        <Grid md={1}>
                                            <SecondaryButton click={()=> setRecoverPassword(false)} text="Cancelar" full={true} />
                                        </Grid>
                                        <Grid md={1}>
                                            <PrimaryButton click={() => recoveryPasswordEmail()} text="Confirmar" full={true} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        }

                    </Box>
                </Box>
            </Box>
            <Box display={{xs: "block", md: "none"}} m={0} p={0} sx={{width: "100vw", height: "100dvh", backgroundColor: theme.vars.palette.background.body}}>
                <Box display="flex" justifyContent="center" width="100%">
                    <Box>
                        <Box  display="flex" justifyContent="center" mt="50px">
                            <MuneyIcon />
                        </Box>
                        <Box mt={5} >
                            <Box display="flex" justifyContent="center">
                                <Typography sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "120%",
                                    textAlign: "center",
                                    color: theme.vars.palette.text.main,
                                }}>
                                    No disponible en Dispositivos móviles
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Login;