import { useState } from 'react';
import axios from 'axios';

const useGet = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const get = async (url, token = null) => {
    setIsLoading(true);

    const config = {
      headers: {}
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await axios.get(process.env.REACT_APP_URL_API + url, config);
      setError(null);
      if(response.data.data)
        return response.data.data;
      return response.data;
    } catch (err) {
      if(err.response.status === 401){
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.removeItem("User");
        }
        window.location.reload();
      }
      setError(err.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  return { error, isLoading, get };
};

export default useGet;