import { Box, Input, List, ListItem, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";

const AutocompleteClient = ({ getLocation, center, coordinates, height = "300px" }) => {
  const theme = useTheme();

  const [query, setQuery] = useState('');
  const [predictions, setPredictions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value.length > 0) {
      autocompletePredictions(value);
    } else {
      setPredictions([]);
    }
  };

  const handlePredictionClick = (prediction) => {
    setQuery(prediction.description);
    setPredictions([]);
    getCoordinates(prediction)
  };

  const autocompletePredictions = (input) => {
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPredictions(predictions);
      }
    });
  };

  const getCoordinates = (prediction) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        getLocation( location.lat(), location.lng() ); 
      } else {
        console.error("Geocode was not successful for the following reason:", status);
      }
    });
  };

  useEffect(()=>{
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: center.lat, lng: center.lng };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setQuery(results[0].formatted_address);
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: ", status);
      }
    });
  }, [center])

  return (
    <Box sx={{position: "relative"}}>
      <Input
        value={query}
        type="text"
        placeholder="Ingrese una ubicación válida"
        onChange={handleInputChange}
        sx={{
          display: "flex",
          width: "100%",
          padding: "8px 16px",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          color: theme.vars.palette.text.main,
          gap: "8px",
          borderRadius: "12px",
          border: "2px solid #18E9E9",
          backgroundColor: theme.vars.palette.background.body,
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "120%",
        }} />
      {predictions.length > 0 && (
        <List
          sx={{
            position: 'absolute',
            zIndex: 2000,
            maxHeight: '200px',
            overflowY: 'auto',
            backgroundColor: theme.vars.palette.background.body,
            border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
            borderRadius: 'sm',
            boxShadow: 'sm',
          }}
        >
          {predictions.map((prediction, index) => (
            <ListItem
              key={index}
              onClick={() => handlePredictionClick(prediction)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.vars.palette.neutral.plainHoverBg,
                },
              }}
            >
              <Typography
                level="body2"
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  color: theme.vars.palette.text.primary,
                }}
              >
                {prediction.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default AutocompleteClient;