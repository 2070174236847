import { Box, Input, useTheme } from "@mui/joy";
import { useState } from "react";

const CheckEye = ({show}) => {
    return(
        <Box m={0} p={0} onClick={()=> show()} sx={{cursor: "pointer"}}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3866 8.77796C10.3866 10.098 9.31995 11.1646 7.99995 11.1646C6.67995 11.1646 5.61328 10.098 5.61328 8.77796C5.61328 7.45796 6.67995 6.3913 7.99995 6.3913C9.31995 6.3913 10.3866 7.45796 10.3866 8.77796Z" stroke="#18E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.9999 14.2913C10.3532 14.2913 12.5466 12.9046 14.0732 10.5046C14.6732 9.56463 14.6732 7.98463 14.0732 7.04463C12.5466 4.64463 10.3532 3.25797 7.9999 3.25797C5.64656 3.25797 3.45323 4.64463 1.92656 7.04463C1.32656 7.98463 1.32656 9.56463 1.92656 10.5046C3.45323 12.9046 5.64656 14.2913 7.9999 14.2913Z" stroke="#18E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Box>
    )
}

const CustomInput = ({placeholder, width, password = false, type = "", setValue, value}) =>{
    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);

    const show = () => {
        setShowPassword(prev => !prev);
    }

    return(
        <>
            {
                password ? (
                    <Input 
                        value={value}
                        type={showPassword ? "text" : "password"}
                        endDecorator={<CheckEye show={show} />}
                        placeholder={placeholder}
                        onChange={(e) => setValue(e.target.value)}
                        sx={{
                            display: "flex",
                            width: {width},
                            padding: "8px 16px",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            color: theme.vars.palette.text.main,
                            gap: "8px",
                            borderRadius: "12px",
                            border: "2px solid #18E9E9",
                            backgroundColor: theme.vars.palette.background.body,  
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "120%",                
                        }} 
                    />
                ) : (
                    <Input 
                        value={value}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => setValue(e.target.value)}
                        sx={{
                            display: "flex",
                            width: {width},
                            padding: "8px 16px",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            color: theme.vars.palette.text.main,
                            gap: "8px",
                            borderRadius: "12px",
                            border: "2px solid #18E9E9",
                            backgroundColor: "transparent",  
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "120%",                
                        }} 
                    />
                )
            }
        </>
    )
}

export default CustomInput;