import { Box, Grid, Typography, useTheme } from "@mui/joy";

import TitleDocument from "../titleDocument";
import PrimaryButton from "../../UI/custom_elements/primaryButton" 
import SecondaryButton from "../../UI/custom_elements/secondaryButton" 

const Step1 = ({step, nextStep ,decline , title, url, file_1, commercial_name}) => {

    const theme = useTheme();

    const handleDownload = (new_url) => {
        window.open(new_url, '_blank');
    };
    
    return (

        <>
            <TitleDocument step={step} title={title} />
            <Box sx={{
                borderRadius: "24px",
                padding: "24px",
                backgroundColor: theme.vars.palette.card
            }}>
                <Box>
                    <Box>
                        <Typography sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            Nombre del comercio: {commercial_name ? commercial_name : "Nombre indefinido"}
                        </Typography>
                    </Box>
                    <Box mt={4}>
                        <Typography sx={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "150%",
                            color: theme.vars.palette.text.main,
                        }}>
                            {file_1}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <SecondaryButton click={() => handleDownload(url)} text="Descargar" document={true} table={true} full={true} />
                    </Box>
                </Box>
            </Box>
            <Box mt={2}>
                <Grid container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <SecondaryButton click={()=> decline()} text="Rechazar" full={true} />
                    </Grid>
                    <Grid md={1}>
                        <PrimaryButton click={() => nextStep()} text={step === 10 ? "Validar" : "Siguiente"} full={true} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
} 

export default Step1;