import { useTheme } from "@mui/joy";

const Edit = () => {

    const theme = useTheme();

    return(
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3335 1.83333H6.00016C2.66683 1.83333 1.3335 3.16666 1.3335 6.5V10.5C1.3335 13.8333 2.66683 15.1667 6.00016 15.1667H10.0002C13.3335 15.1667 14.6668 13.8333 14.6668 10.5V9.16666" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.6933 2.51333L5.43992 7.76667C5.23992 7.96667 5.03992 8.36 4.99992 8.64667L4.71325 10.6533C4.60659 11.38 5.11992 11.8867 5.84659 11.7867L7.85325 11.5C8.13325 11.46 8.52659 11.26 8.73325 11.06L13.9866 5.80667C14.8933 4.9 15.3199 3.84667 13.9866 2.51333C12.6533 1.18 11.5999 1.60667 10.6933 2.51333Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.93994 3.26666C10.3866 4.86 11.6333 6.10666 13.2333 6.56" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Edit;