import { Box, useTheme } from "@mui/joy";
import SideBar from "../custom_elements/sideBar";
import { useContext, useEffect } from "react";
import SessionContext from "../../../store";
import { useNavigate } from "react-router-dom";

const Home = ({children}) => {
    const theme = useTheme();
    const { setSession } = useContext(SessionContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.localStorage) {
            const storedSession = JSON.parse(localStorage.getItem("User"));
            if (storedSession) {
                setSession({
                    email: storedSession.email,
                    type: storedSession.type,
                    token: storedSession.token       
                });
                navigate("/dashboard")
            }
            else{
                navigate("/")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);    

    return (
        <Box sx={{backgroundColor: theme.vars.palette.background.body}}>
            <Box sx={{ display: "flex", maxWidth: "100vw"}}>
                <Box sx={{height: "100dvh", maxHeight: "100dvh", overflowY: "auto", position: "fixed", width: "232px"}}>
                    <SideBar />
                </Box>
                <Box display="flex" sx={{ overflowY: "auto", minHeight: "100dvh", marginLeft: "232px" }}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default Home;