import { Box, Grid, Typography } from "@mui/joy";

import Active from "../icons/active"
import Suspended from "../icons/suspended"
import Inactive from "../icons/inactive"

const TableTransactions = ({elements, theme, columns}) =>{

    const mapType = (type) => {
        switch (type) {
          case 'transfer':
            return 'Muney a muney';
          case 'commission':
            return 'Comisión de merchant';
          case 'muney-commission':
            return 'Comisión de muney';
          case 'withholding':
            return 'Retención';
          case 'crypto-withdrawal':
            return 'Retiro de criptos';
          default:
            return type;
        }
    };

    const mapSubType = (subType) => {
        switch (subType) {
          case 'outgoing':
            return 'Saliente';
          case 'incoming':
            return 'Entrante';
          case 'transaction-fee':
            return 'Comisión por transacción';
          case 'platform-fee':
            return 'Comisión por uso de la plataforma';
          default:
            return subType;
        }
    };
    
    const mapAtmType = (subType) => {
        switch (subType) {
          case 'deposit':
            return 'Transacción de depósito';
          case 'withdrawal':
            return 'Transacción de retiro';
          default:
            return subType;
        }
    }; 

    return(
        <>
            {
                elements.map((element, index)=>{
                    return(
                        <Grid 
                            key={index}
                            container 
                            spacing={2} 
                            mb={2}
                            columns={columns} 
                            sx={{
                                flexGrow: 1,
                                borderRadius: "12px",
                                padding: "8px",
                                backgroundColor: theme.vars.palette.card
                            }}>
                                <Grid display="flex" justifyContent="space-between" alignItems="center" md={1}>
                                    {
                                        element.status === "completed" && (
                                            <Box>
                                                <Active />
                                            </Box>
                                        )
                                    }
                                    {
                                        element.status === "inactive" && (
                                            <Box>
                                                <Inactive />
                                            </Box>
                                        )
                                    }
                                    {
                                        element.status === "suspended" && (
                                            <Box>
                                                <Suspended />
                                            </Box>
                                        )
                                    }
                                    {
                                        element.status === "pending" && (
                                            <Box>
                                                <Inactive />
                                            </Box>
                                        )
                                    }
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        overflowWrap: "break-word",
                                        maxWidth: "70px",
                                        textAlign: "center"
                                    }}>
                                        {element.id}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.createdAt.split('T')[0]}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.type ? mapType(element.type) : "Tipo no registrado"}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.sub_type ? mapSubType(element.sub_type) : "Subtipo no registrado"}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.atm_type ? mapAtmType(element.atm_type) : "-"}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {Number(element.amount || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.sender && element.sender.business_name != null ? element.sender.business_name : (element.sender && element.sender.country_code && element.sender.mobile) ? element.sender.country_code + element.sender.mobile : ""}
                                    </Typography>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main,
                                        textAlign: "center"
                                    }}>
                                        {element.receiver && element.receiver.business_name != null ? element.receiver.business_name : (element.receiver && element.receiver.country_code && element.receiver.mobile) ? element.receiver.country_code + element.receiver.mobile : ""}
                                    </Typography>
                                </Grid>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default TableTransactions;