const ClientsIcon = ({theme}) => {

    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13.0733H11.4933C10.96 13.0733 10.4533 13.28 10.08 13.6533L8.93998 14.78C8.41998 15.2933 7.57334 15.2933 7.05334 14.78L5.91333 13.6533C5.54 13.28 5.02667 13.0733 4.5 13.0733H4C2.89333 13.0733 2 12.1867 2 11.0933V3.81998C2 2.72664 2.89333 1.84 4 1.84H12C13.1067 1.84 14 2.72664 14 3.81998V11.0933C14 12.18 13.1067 13.0733 12 13.0733Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 7.16668C8.85788 7.16668 9.55334 6.47121 9.55334 5.61333C9.55334 4.75545 8.85788 4.06003 8 4.06003C7.14212 4.06003 6.44666 4.75545 6.44666 5.61333C6.44666 6.47121 7.14212 7.16668 8 7.16668Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.6667 10.94C10.6667 9.73999 9.47334 8.76669 8.00001 8.76669C6.52668 8.76669 5.33334 9.73999 5.33334 10.94" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ClientsIcon;