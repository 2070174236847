import { Box, Stack, Tooltip, Typography, useColorScheme, useTheme } from "@mui/joy";
import MuneyIcon from "../../icons/muney";
import { useContext, useEffect, useState } from "react";
import UIContext from "../../../store/ui";
import { useNavigate } from "react-router-dom";
import SessionContext from "../../../store";

const SideBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { menu, setActiveMenuItem, menuOperator, setActiveMenuOperatorItem } = useContext(UIContext);
    const { session, logOut } = useContext(SessionContext);

    const [actualMenu, setActualMenu] = useState({
        menu: [],
        changeMenu: () => {}
    });

    useEffect(()=>{
        if(session.type === "admin"){
            setActualMenu(prevUser => ({
                ...prevUser,
                menu: menu,
                changeMenu: setActiveMenuItem
            }));
        }
        else{
            setActualMenu(prevUser => ({
                ...prevUser,
                menu: menuOperator,
                changeMenu: setActiveMenuOperatorItem
            }));
        }
    // eslint-disable-next-line
    },[session]);

    const [openConfiguration, setOpenConfiguration] = useState(false);

    const { mode, setMode } = useColorScheme();

    const closeSession = async () => {
        try{
            await logOut();
            setActiveMenuItem(0)
            navigate("/");
        }
        catch(error){

        }
    }

    return (
        <>
            <Box 
                sx={{
                    maxHeight: "100dvh",
                    height: "100%",
                    backgroundColor: theme.palette.background.sideBar,
                    position: "relative"
                }}
            >
                <Box display="flex" justifyContent="end" padding="16px">
                    <Box m={0} p={0} sx={{cursor: "pointer"}} onClick={()=> {navigate("/dashboard"); actualMenu.changeMenu(0);}}>
                        <MuneyIcon />
                    </Box>
                </Box>
                <Tooltip
                    placement="top-end"
                    type="plain"
                    sx={{
                        backgroundColor:  theme.vars.palette.background.body, borderRadius: "16px", paddingX: "8px", paddingY: "16px"
                    }}
                    title={
                        openConfiguration && (
                            <Box sx={{width: "224px" ,}}>
                                <Box>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main
                                    }}>
                                        {session && session.type ? session.type === "admin" ? "Administrador" : session.type : ""}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "130%",
                                        color: theme.vars.palette.text.main
                                    }}>
                                        {session && session.email ? session.email : ""}
                                    </Typography>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main
                                    }}>
                                        Dark theme
                                    </Typography>
                                    <Box onClick={()=> {setMode(mode === "dark" ? "light" : "dark")}} display="flex" justifyContent={mode === "dark" ? "end" : "start"} sx={{width: "48px", borderRadius: "14px", border: "2px solid", borderColor: theme.vars.palette.primary["500"], padding: "4px", cursor: "pointer"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <circle cx="9" cy="9" r="9" fill="#18E9E9"/>
                                        </svg>
                                    </Box>
                                </Box>
                                <Box onClick={()=> closeSession()} mt={4} sx={{
                                    display: "flex",
                                    padding: "8px 16px",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    borderRadius: "12px",
                                    backgroundColor: theme.vars.palette.background.sideBar
                                }}>
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        lineHeight: "120%",
                                        color: theme.vars.palette.text.main
                                    }}>
                                        Cerrar sesión
                                    </Typography>
                                </Box>
                            </Box>
                        ) 
                    }
                    >
                        <Box 
                            onClick={()=> {
                                setOpenConfiguration(prev => !prev)
                            }}
                            sx={{
                                position: "absolute",
                                bottom: "10px",
                                left: "45px",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                width: "80%",
                                height: "37px",
                                borderRadius: "8px 0px 0px 8px",
                                cursor: "pointer",
                                backgroundColor: openConfiguration ? theme.vars.palette.background.configuration : "transparent"
                            }}
                        >
                            <Typography sx={{
                                fontFamily: "Montserrat",
                                fontSize: "13px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "150%",
                                color: openConfiguration ? theme.vars.palette.background.body : theme.vars.palette.text.main
                            }}>
                                Configuración
                            </Typography>
                            <Box ml={2} mr={3} sx={{position: "relative"}}>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00016 15.1667H10.0002C13.3335 15.1667 14.6668 13.8334 14.6668 10.5V6.50004C14.6668 3.16671 13.3335 1.83337 10.0002 1.83337H6.00016C2.66683 1.83337 1.3335 3.16671 1.3335 6.50004V10.5C1.3335 13.8334 2.66683 15.1667 6.00016 15.1667Z" stroke={openConfiguration ? theme.vars.palette.background.body : theme.vars.palette.text.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.64648 9.47338L7.99982 7.12671L10.3532 9.47338" stroke={openConfiguration ? theme.vars.palette.background.body : theme.vars.palette.text.main} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Box>
                        </Box>
                </Tooltip>
                <Stack mt={5} alignItems="flex-end">
                    {
                        actualMenu.menu.map((item, index)=>{
                            return(
                                <Box 
                                    key={index} 
                                    onClick={()=> {
                                        actualMenu.changeMenu(index)
                                        navigate(item.route)
                                    }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        width: "80%",
                                        height: "37px",
                                        borderRadius: "8px 0px 0px 8px",
                                        cursor: "pointer",
                                        backgroundColor: item.state === true ? theme.palette.background.body : "transparent"
                                    }}
                                >
                                    <Typography sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "13px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "150%",
                                        color: theme.vars.palette.text.main
                                    }}>
                                        {item.name}
                                    </Typography>
                                    <Box ml={2} mr={3}>
                                        {item.icon}
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Stack>
            </Box>
        </>
    )
}

export default SideBar;