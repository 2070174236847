import { Box, Grid, Typography, useTheme } from "@mui/joy";

import PrimaryButton from "../UI/custom_elements/primaryButton";
import { useContext, useEffect, useState } from "react";
import SessionContext from "../../store";
import { Loading } from "../UI/custom_elements/loading";
import useGet from "../../hooks/useGet";

const DocumentsReview = ({ close, title, merchant }) => {

    const theme = useTheme();

    const [merchantInfo, setMerchantInfo] = useState(undefined);

    const { session } = useContext(SessionContext);

    const { error, isLoading, get } = useGet();

    const fetchData = async () => {
        try {
            const data = await get("/admin/merchant/requests/" + merchant, session.token);
            setMerchantInfo(data)
            console.log("DATA: ", data)
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        console.log(error);
    }

    const getOldDocumentName = (currentName) => {
        const documentNameMap = {
            "business_name": "Verificación del nombre del comercio",
            "identity_name_number": "Verificación del nombre y número de identificación con respecto al acta constitutiva",
            "identity_expiration": "Verificación de vigencia de la identificación oficial",
            "rif_business_name": "Verificación de razón social en el acta constitutiva con el RIF",
            "rif_expiration": "Vigencia de RIF",
            "rif_business_name_number": "Verificación de razón social en el acta constitutiva y el número de registro de RIF",
            "tax_declaration": "Declaración de impuestos (debe ser del año anterior)",
            "address_rif": "Dirección del comprobante de domicilio coincida con la del RIF",
            "location_metadata": "Verificación de metadatos coincida con la foto de la ubicación validada por la app",
            "business_ownership": "Dominio sobre el establecimiento (fotos tomadas con libertad)",
        };
        return documentNameMap[currentName] || currentName;
    };

    return (

        <>
            <Box>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                borderRadius: "24px",
                padding: "24px",
                backgroundColor: theme.vars.palette.card,
                maxHeight: "400px",
                minHeight: "400px",
                width: "400px",
                overflowY: "auto"
            }}>
                <Box>
                    {
                        isLoading ? (
                            <Box display="flex" justifyContent="center" height={300}>
                                <Loading />
                            </Box>
                        ) : (
                            <Box>
                                {merchantInfo?.validations.map((validation, index) => (
                                    <Box key={index} sx={{
                                        borderRadius: "24px",
                                        padding: "24px",
                                        backgroundColor: theme.vars.palette.background.body,
                                        mt: 2,
                                    }}>
                                        <Box>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                lineHeight: "150%",
                                                color: theme.vars.palette.text.main,
                                            }}>
                                                Documento: {getOldDocumentName(validation.document)}
                                            </Typography>
                                        </Box>
                                        <Box mt={2}>
                                            {validation.approved ? (
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    lineHeight: "150%",
                                                    color: 'green',
                                                }}>
                                                    Aprobado
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        lineHeight: "150%",
                                                        color: 'red',
                                                    }}>
                                                        Rechazado
                                                    </Typography>
                                                    {validation.reason && (
                                                        <Typography sx={{
                                                            fontFamily: "Montserrat",
                                                            fontSize: "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: 500,
                                                            lineHeight: "150%",
                                                            color: theme.vars.palette.text.main,
                                                        }}>
                                                            Razón: {validation.reason}
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                                {
                                    merchantInfo?.validations.length === 0 && (
                                        <Box sx={{
                                            borderRadius: "24px",
                                            padding: "24px",
                                            backgroundColor: theme.vars.palette.background.body,
                                            mt: 2,
                                        }}>
                                            <Box>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                }}>
                                                    Validaciones no recibidas
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <Box mt={2}>
                <Grid container columns={1} spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid md={1}>
                        <PrimaryButton click={() => close()} text={"Cerrar"} full={true} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default DocumentsReview;