import { Box, Grid, Typography } from "@mui/joy";
import PrimaryButton from "../UI/custom_elements/primaryButton";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import { useContext } from "react";
import usePost from "../../hooks/usePost";
import SessionContext from "../../store";

const ChangeStatus = ({userId, status, close, fetchData}) => {

    const { session } = useContext(SessionContext);

    const { post } = usePost();


    const save = async () => {
        const response = await post("/admin/users/status/"+ userId, {status: status}, session.token);
        if(response){
            fetchData();
            close();
        }
    }

    return (
        <>
            <Box width={500}>
                <Box display="flex" justifyContent="center" my={4}>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "150%",
                        color: "red",
                    }}>
                        ¿Usted desea cambiar el estatus de este usuario a {status}?
                    </Typography>
                </Box>
                <Grid mt={2} container columns={2} spacing={2} sx={{flexGrow: 1}}>
                    <Grid md={1}>
                        <SecondaryButton click={()=> close()} text="Cancelar" full={true} />
                    </Grid>
                    <Grid md={1}>
                        <PrimaryButton click={()=> save()} text="Aceptar" full={true} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
} 

export default ChangeStatus;